import { useMutation } from '@tanstack/react-query';
import { ApiError, ITEPortal_Domain_Dto_AddProductDto } from '@/api';
import { typedAxios } from '@/api/typed-axios';
import { toast } from 'react-toastify';
import fetchApi from '@/utils/fetchAPI';
import { SAVE_PRODUCT_IMAGES } from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { CommonProductValidationSchemaType } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/validation-schemas';
import { useTranslation } from 'react-i18next';

export const useCommonProductSave = <
  T extends CommonProductValidationSchemaType
>() => {
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['save-common-product'],
    mutationFn: async (opts: {
      values: T;
      isUpdate: boolean;
      map: (v: T) => ITEPortal_Domain_Dto_AddProductDto;
    }) => {
      const { values, isUpdate, map } = opts;
      const categoryName = values.categoryName?.value as string;
      const productData = map(values);

      try {
        if (isUpdate) {
          await typedAxios.product.putProducts({
            requestBody: { ...productData, categoryName },
          });
        } else {
          await typedAxios.product.postProducts({
            requestBody: { ...productData, categoryName },
          });
        }

        if (values.imageFiles && values.imageFiles[0] instanceof File) {
          const formData = new FormData();

          values.imageFiles.forEach((file) => formData.append('images', file));

          await fetchApi(
            SAVE_PRODUCT_IMAGES(values?.name),
            {
              method: FetchMethods.Post,
              data: formData,
            },
            { 'Content-Type': 'multipart/form-data' }
          );
        }

        toast.success(t);
      } catch (error) {
        if (error instanceof ApiError && typeof error.body === 'string') {
          toast.error(error.body);
        }
      }
    },
  });
};
