import Select, { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FieldProps } from '@/components/Fields/interfaces';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FieldValues, useController, useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import { OptionType } from '../../model/common';
import { isArrayOfOptions, isOption } from '../../utils/is-option';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
`;

interface SelectComponentProps<T extends FieldValues = FieldValues>
  extends Omit<FieldProps<T>, 'settings'> {
  options?: OptionType[];
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  blurInputOnSelect?: boolean;
  defaultValue?: OptionType | OptionType[];
  maxWidth?: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  allowCreate?: boolean;
}

export const SelectComponent = <T extends FieldValues = FieldValues>({
  label,
  options,
  isRequired,
  isMulti = false,
  closeMenuOnSelect = true,
  blurInputOnSelect = true,
  maxWidth,
  onChange,
  disabled,
  name,
  allowCreate,
  ...props
}: SelectComponentProps<T>): JSX.Element => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error(
      `This field (${name}) should be used only within FormContext`
    );
  }

  const { fieldState, field } = useController({
    name: name,
    control: formContext.control,
  });

  const value = useMemo(() => {
    if (Array.isArray(field.value)) {
      if (field.value.every(isOption)) {
        return field.value;
      }
    }

    if (isOption(field.value)) {
      return field.value;
    }

    if (Array.isArray(options) && !Array.isArray(field.value)) {
      const option = options?.find((opt) => opt.value === field.value);

      if (option) {
        return option;
      }
    }

    if (Array.isArray(options) && isArrayOfOptions(field.value)) {
      const possibleValues = field.value
        // @ts-expect-error todo fix types
        .map((option) => option.value);

      return options.filter((opt) => possibleValues.includes(opt.value));
    }

    return null;
  }, [field, options]);

  if (!fieldState) {
    throw new Error(
      'fieldState not found. Is this field used within FormContext?'
    );
  }

  const { t } = useTranslation();

  const selectStylesOverride: StylesConfig = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#f7f7f7' : 'inherit',
      borderColor: fieldState.invalid ? 'red' : 'inherit',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }),
    option: (styles) => ({ ...styles, minHeight: '40px' }),
    menuPortal: (styles) => ({ ...styles, zIndex: 10000 }),
  };

  const BaseComponent = allowCreate ? CreatableSelect : Select;

  return (
    <Box maxWidth={maxWidth}>
      <FormControlWrapper
        isInvalid={fieldState.invalid}
        isRequired={isRequired}
      >
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <BaseComponent
          id={name}
          options={options}
          styles={selectStylesOverride}
          closeMenuOnSelect={closeMenuOnSelect}
          blurInputOnSelect={blurInputOnSelect}
          placeholder={t('select')}
          isMulti={isMulti}
          defaultValue={formContext.getValues(name)}
          isDisabled={disabled}
          isClearable
          menuPlacement="auto"
          // menuIsOpen={true}
          {...props}
          {...field}
          value={value}
          menuPortalTarget={document.body}
          onChange={(event) => {
            if (event) {
              field.onChange(event);

              if (typeof onChange === 'function') {
                onChange(event);
              }
            } else if (event === null) {
              // reset field state and propagate clearing select
              formContext.resetField(name);
            }
          }}
        />
        {fieldState.error?.message && (
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        )}
      </FormControlWrapper>
    </Box>
  );
};
