import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import { Column, Row } from 'react-table';
import { Table } from '@/components/Table/Table';
import { Card } from '@/components/Card/Card';
import { toast } from 'react-toastify';
import { Button } from '@/components/Buttons/Button/Button';
import { ROLES } from '@/constants/admin-pages';
import { User } from '@/model/IUser';
import { useNavigate } from '@tanstack/react-router';
import { DeleteModal } from '@/pages/Exhibition/DeleteModal';
import { typedAxios } from '@/api/typed-axios';
import { useTableLoader } from '@/components/Table/useTableLoader';
import { ITEPortal_Domain_Dto_UserDto } from '@/api';
import { LoaderWrapper } from '@/components/Loader/Loader';
import { useRBAC } from '@/features/rbac/useRBAC';

const OptionWrapper = styled(Flex)`
  flex-direction: row;
  gap: 20px;
`;

const ButtonContainer = styled(Box)`
  padding-top: 20px;
`;

interface UsersTableOptionsProps {
  row: Row<ITEPortal_Domain_Dto_UserDto>;
}

export const UsersTable = (): JSX.Element => {
  const { t } = useTranslation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [userIdValue, setUserIdValue] = useState();
  const navigate = useNavigate();
  const { can } = useRBAC();

  const {
    data = [],
    isLoading,
    refresh,
  } = useTableLoader<ITEPortal_Domain_Dto_UserDto>({
    resource: 'users',
    additionalPrams: {
      roles: [
        User.Role.SuperAdmin,
        User.Role.Admin,
        User.Role.Moderator,
        User.Role.SupportManager,
        User.Role.TechnicalManager,
      ],
    },
    xhrOptions: {
      paramsSerializer: {
        indexes: null,
      },
    },
  });

  const openDeleteModal = (userId: any) => {
    setUserIdValue(userId);
    setOpenConfirm(!openConfirm);
  };

  const closeDeleteModal = () => setOpenConfirm(false);

  const UsersTableOptions = ({ row }: UsersTableOptionsProps) => {
    const rowValues = row.values;

    if (
      row.original.role === User.Role.SuperAdmin &&
      !can('canManageSuperAdmins')
    ) {
      return null;
    }

    return (
      <OptionWrapper>
        <Link onClick={() => editUser(rowValues?.id)}>
          <EditIcon />
        </Link>
        <Link onClick={() => openDeleteModal(rowValues?.id)}>
          <DeleteIcon />
        </Link>
      </OptionWrapper>
    );
  };

  const UserRole = ({ row }: UsersTableOptionsProps): JSX.Element => {
    const role = row?.values?.role as number;

    return (
      <Text>
        {role ? ROLES.find((userRole) => userRole?.value === role)?.label : ''}
      </Text>
    );
  };

  const deleteUser = async () => {
    if (userIdValue) {
      try {
        await typedAxios.user.deleteUsersDelete({
          id: userIdValue,
        });

        refresh();
      } finally {
        toast.error(t('error-occurred'));
      }
    }
  };

  const usersTableColumns = React.useMemo<
    Array<Column<ITEPortal_Domain_Dto_UserDto>>
  >(
    () => [
      {
        Header: `ID`,
        accessor: 'id',
        show: false,
      },
      {
        Header: `${t('full-name')}`,
        accessor: 'name',
        width: '20%',
      },
      {
        Header: `${t('emailPlaceholder')}`,
        accessor: 'email',
        width: '35%',
      },
      {
        Header: `${t('role')}`,
        accessor: 'role',
        width: '35%',
        Cell: ({ row }) => <UserRole row={row} />,
      },
      {
        Header: '',
        id: 'options',
        width: '5%',
        Cell: ({ row }) => <UsersTableOptions row={row} />,
      },
    ],
    []
  );

  const addUser = () => {
    navigate({ to: '/admin/users/add' });
  };

  const editUser = (id: string) => {
    navigate({
      to: '/admin/users/$userId/edit',
      params: { userId: id },
    });
  };

  return (
    <LoaderWrapper loading={isLoading}>
      <Card>
        <DeleteModal
          onSubmit={deleteUser}
          isOpen={openConfirm}
          onClose={closeDeleteModal}
        />
        <Table columns={usersTableColumns} data={data} />
        <ButtonContainer>
          <Button colorScheme="red" onClick={addUser}>
            {t('add-user')}
          </Button>
        </ButtonContainer>
      </Card>
    </LoaderWrapper>
  );
};
