import { redirect, Route } from '@tanstack/react-router';
import { flatRouteTree } from '../protected';
import Exhibition from '../../../pages/Exhibition';
import Cart from '../../../pages/Cart';
import Categories from '../../../pages/Order/Categories';
import Service from '../../../pages/Order/Service';
import { Loader } from '@/components/Loader/Loader';

import { typedAxios } from '@/api/typed-axios';
import { ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto } from '@/api';

import { leaveExhibitionOrderEvent } from '@/store/effector/cleanup';
import { $exhibition, exhibitionApi } from '@/store/effector/exhibition';

import { CatalogGateHOC } from '@/store/effector/catalog-gate';
import { QueryOptions } from '@/features/query/query-options';
import { QueryClient } from '@tanstack/react-query';
import { IExhibition } from '@/model/IExhibition';
import { $stand, standApi } from '@/store/effector/stand';
import { StandProject } from '@/pages/StandProject';
import { routerRBACGuard } from '@/features/Routing/guard';

export const exhibitionRootRoute = new Route({
  path: '/exhibitions',
  getParentRoute: () => flatRouteTree,
  onLeave: () => {
    leaveExhibitionOrderEvent();
  },
  pendingComponent: () => <Loader centralized />,
  beforeLoad: async (opts) => {
    // @ts-expect-error for root route there is no such param, but for child routes it will be
    const { exhibitionId } = opts.params;
    const {
      context: { user },
    } = opts;

    await routerRBACGuard(user, 'canAccessExhibitionProfile');

    if (!exhibitionId) {
      return { exhibition: null };
    }

    const exhibitionInstance = $exhibition.getState().exhibition;

    if (exhibitionInstance instanceof IExhibition) {
      if (exhibitionInstance.id == exhibitionId) {
        return {
          exhibition: exhibitionInstance,
        };
      }
    }

    const exhibition = await typedAxios.exhibition.getExhibitions({
      id: +exhibitionId,
    });

    const exhibitionNewInstance = new IExhibition({
      id: +exhibitionId,
      ...exhibition,
    });

    exhibitionApi.setInstance(exhibitionNewInstance);

    return { exhibition: exhibitionNewInstance };
  },
});

export const exhibitionViewRoute = new Route({
  path: '$exhibitionId',
  getParentRoute: () => exhibitionRootRoute,
  loader: async (opts) => {
    const { exhibitionId } = opts.params;
    const data = await typedAxios.stand.getExhibitionsExhibitorStands({
      exhibitionId: +exhibitionId,
    });

    return { standsData: data };
  },
  pendingComponent: () => <Loader centralized />,
  component: () => <Exhibition />,
});

export const viewExhibitionCart = new Route({
  path: '$exhibitionId/$stand/cart',
  getParentRoute: () => exhibitionRootRoute,
  component: () => <Cart />,
  loader: async ({ params, context: { queryClient } }) => {
    const cleaningDiscount = await typedAxios.discount
      .getDiscountsCleaning()
      .then((response) => response)
      .catch(() => {
        return [] as ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto[];
      });

    await invalidateStand({
      standId: +params.stand,
      queryClient,
    });

    return {
      cleaningDiscount,
    };
  },
});

const invalidateStand = async (opts: {
  standId: number;
  queryClient: QueryClient;
}) => {
  try {
    const { queryClient, standId } = opts;
    const stand = await queryClient.ensureQueryData(
      QueryOptions.loadExhibitorStand({
        standId,
      })
    );

    const exhibition = $exhibition.getState();

    if (
      exhibition.sellingOfficeId !== stand.sellingOfficeId ||
      exhibition.exhibitionId !== stand.exhibitionId ||
      exhibition.currency !== stand.currency
    ) {
      exhibitionApi.update({
        sellingOfficeId: stand.sellingOfficeId,
        exhibitionId: stand.exhibitionId,
      });
    }

    if ($stand.getState().standForOrder?.standId !== stand.standId) {
      standApi.setStandForOrder(stand);
    }
  } catch {}
};

export const createExhibitionOrderCategoryRoute = new Route({
  path: '$exhibitionId/$stand/create',
  getParentRoute: () => exhibitionRootRoute,
  component: () => {
    return (
      <CatalogGateHOC>
        <Categories title={'technicalServices'} />
      </CatalogGateHOC>
    );
  },
  beforeLoad: async ({ params, context: { exhibition, queryClient } }) => {
    if (exhibition && exhibition.isPast) {
      throw redirect({
        to: exhibition.id ? `/exhibitions/${exhibition.id}` : '/',
      });
    }

    await invalidateStand({
      queryClient,
      standId: +params.stand,
    });
  },
});

export const createExhibitionOrderServiceRoute = new Route({
  path: '$exhibitionId/$stand/create/$service',
  getParentRoute: () => exhibitionRootRoute,
  component: () => {
    return (
      <CatalogGateHOC>
        <Service />
      </CatalogGateHOC>
    );
  },
  beforeLoad: async ({ params, context: { queryClient } }) => {
    await invalidateStand({
      queryClient,
      standId: +params.stand,
    });
  },
});

export const standProjectRoute = new Route({
  path: '$exhibitionId/$stand/project/$projectId',
  getParentRoute: () => exhibitionRootRoute,
  component: StandProject,
  loader: async ({
    context: { queryClient },
    params: { stand: standId, exhibitionId },
  }) => {
    const { exhibitionName } = $exhibition.getState();

    if (!exhibitionName) {
      const exhibition = await typedAxios.exhibition.getExhibitions({
        id: +exhibitionId,
      });

      exhibitionApi.update({
        exhibitionName: exhibition.name,
      });
    }

    const project = await queryClient.fetchQuery(
      QueryOptions.loadStandProject({
        standId: +standId,
      })
    );

    return {
      project,
    };
  },
});
