import React, { useState } from 'react';
import { Flex, Link, Button, Box, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  DeleteIcon,
  EditIcon,
  DownloadIcon,
  EmailIcon,
  InfoIcon,
} from '@chakra-ui/icons';
import styled from 'styled-components';

import { Card } from '@/components/Card/Card';
import { Table } from '@/components/Table/Table';
import fetchApi from '@/utils/fetchAPI';
import { DELETE_ORDER_ITEM, GET_PDF_BY_ORDER } from '@/constants/endpoints';
import { EditOrderForm } from '@/pages/AdminPanel/PlacedOrders/EditOrder/EditOrderForm';
import { toast } from 'react-toastify';
import { IdType } from '@/model/common';
import { ConfirmModal } from '@/pages/ConfirmModal';
import { FetchMethods } from '@/utils/constants';
import { NumberColumnComponent } from '../shared/NumberColumnComponent';
import { editOrderRoute } from '../../../../features/Routing/protected/admin-exhibitions';
import { useRouter } from '@tanstack/react-router';
import { PersonalDiscount } from './PersonalDiscount';
import { MarkupManagement } from './MarkupManagement';
import { numberWithSpaces } from '../../../../utils/amountTransformation';
import { AsyncButton } from '../../../../components/AsyncButton';
import { FileUtils } from '../../../../utils/file-utils';
import { useSendOrderEmail } from './hooks/use-send-order-email';
import { Column, Row } from 'react-table';
import type { ITEPortal_Domain_Dto_OrderItemDto } from '../../../../api';
import { isNil } from '../../../../utils/is-nil';
import { useRBAC } from '@/features/rbac/useRBAC';

const AddButtonWrapper = styled(Box)`
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
`;

const TotalRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 25px 10px 0;
  min-width: 225px;
`;

interface OrderOptionsType {
  row: Row<ITEPortal_Domain_Dto_OrderItemDto>;
}

export const EditOrder = (): JSX.Element => {
  const router = useRouter();
  const { can } = useRBAC();
  const { order } = editOrderRoute.useLoaderData();
  const { orderId, exhibitionId } = editOrderRoute.useParams();
  const culture = localStorage.getItem('lang');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [editTableData, setEditTableData] =
    useState<ITEPortal_Domain_Dto_OrderItemDto | null>(null);
  const [orderItem, setOrderItem] = useState<IdType>();
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const { t } = useTranslation();
  const { requestSendOrderEmail } = useSendOrderEmail(orderId);

  const closeConfirmDeleteModal = () => setOpenConfirmDeleteModal(false);

  const closeModal = () => setOpenConfirm(false);

  const OrderRowOptions = ({ row }: OrderOptionsType) => {
    const rowValues = row.original;

    const editProduct = () => {
      setEditTableData(rowValues);
      setOpenConfirm(!openConfirm);
    };

    return (
      <Flex gap={4} flexDirection="row">
        <Link onClick={editProduct}>
          <EditIcon />
        </Link>
        <Link
          onClick={() => {
            if (!isNil(rowValues.orderItemId)) {
              showDeleteModal(rowValues.orderItemId);
            }
          }}
        >
          <DeleteIcon />
        </Link>
      </Flex>
    );
  };

  const showDeleteModal = (id: IdType) => {
    setOrderItem(id);
    setOpenConfirmDeleteModal(!openConfirmDeleteModal);
  };

  const orderColumns = React.useMemo<
    Array<Column<ITEPortal_Domain_Dto_OrderItemDto>>
  >(
    () => [
      {
        Header: t('order-id'),
        accessor: 'id',
        width: '10%',
      },
      {
        Header: t('service-name'),
        accessor: 'productName',
        width: '30%',
        Cell: ({ row: { original } }) => {
          if (!original.included) {
            return <>{original.productName}</>;
          }

          return (
            <>
              {original.productName}{' '}
              <Tooltip
                label={t('included-into-price-of')}
                placement="top"
                hasArrow
              >
                <InfoIcon color="brandColor" />
              </Tooltip>
            </>
          );
        },
      },
      {
        accessor: 'orderItemId',
        show: false,
      },
      {
        Header: `${t('amount')}`,
        accessor: 'amount',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <NumberColumnComponent value={values?.amount} />
        ),
      },
      {
        Header: `${t('dayAmountPlaceholder')}`,
        accessor: 'dayAmount',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <NumberColumnComponent value={values?.dayAmount} />
        ),
      },
      {
        Header: `${t('personAmountPlaceholder')}`,
        accessor: 'peopleAmount',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <NumberColumnComponent value={values?.peopleAmount} />
        ),
      },
      {
        Header: `${t('language')}`,
        accessor: 'language',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <NumberColumnComponent value={values?.language} />
        ),
      },
      {
        Header: '',
        id: 'options',
        width: '5%',
        Cell: ({ row }) => <OrderRowOptions row={row} />,
      },
    ],
    []
  );

  const addProduct = () => {
    setEditTableData(null);
    setOpenConfirm(!openConfirm);
  };

  const downloadPDF = async () => {
    if (order.orderId) {
      try {
        const { data } = await fetchApi(GET_PDF_BY_ORDER(order.orderId), {
          method: FetchMethods.Get,
          responseType: 'blob',
          params: {
            culture,
          },
        });

        FileUtils.saveBlobAsFile(data, `exhibition-order-${order.orderId}.pdf`);
      } catch (e) {
        toast.error(t('unable-to-download', { object: 'PDF' }));
      }
    }
  };

  const deleteOrderItem = async () => {
    try {
      await fetchApi(DELETE_ORDER_ITEM(exhibitionId, orderId), {
        method: FetchMethods.Delete,
        params: {
          orderItemId: orderItem,
        },
      });

      toast.success(t('order-item-deleted'));

      router.invalidate();
    } catch (error: any) {
      const fieldErrors = error?.response?.data?.errors;

      if (fieldErrors) {
        toast.error(fieldErrors);
      }
    }
  };

  return (
    <>
      <AddButtonWrapper>
        <AsyncButton colorScheme="red" onClick={requestSendOrderEmail}>
          <EmailIcon mr={1} />
          {t('send-email')}
        </AsyncButton>
        <AsyncButton marginRight="auto" onClick={downloadPDF}>
          <DownloadIcon mr={1} />
          {t('downloadPdf')}
        </AsyncButton>
        {can('canRemoveSurchargeFromOrder') && (
          <MarkupManagement order={order} />
        )}
        {can('canAddOrderPersonalDiscount') && (
          <PersonalDiscount order={order} />
        )}
        <Button colorScheme="red" onClick={addProduct}>
          {t('add-service')}
        </Button>
      </AddButtonWrapper>
      <Card>
        {openConfirm && (
          <EditOrderForm
            openConfirm={openConfirm}
            closeModal={closeModal}
            editTableData={editTableData}
          />
        )}
        <Table columns={orderColumns} data={order.products || []} />
        <Flex justifyContent="flex-end">
          <TotalRowContainer>
            <Text fontSize="md">{t('cartTotal')}</Text>
            <Text fontSize="md" fontWeight="700">
              {numberWithSpaces(
                order.orderSummary || 0,
                order.currency || 'RUB',
                false
              )}
            </Text>
          </TotalRowContainer>
        </Flex>
        {openConfirmDeleteModal && (
          <ConfirmModal
            modalMessage={t('delete-order-item-message')}
            isOpen={openConfirmDeleteModal}
            closeModal={closeConfirmDeleteModal}
            confirm={deleteOrderItem}
          />
        )}
      </Card>
    </>
  );
};
