import { Route } from '@tanstack/react-router';
import { cabinetRoute } from '../protected';

import { SettingsPage } from '../../../pages/AdminPanel/Settings/SettingsPage';
import { typedAxios } from '../../../api/typed-axios';
import * as v from 'zod';
import { routerRBACGuard } from '@/features/Routing/guard';

export const settingsRoutesRoot = new Route({
  path: '/settings',
  getParentRoute: () => cabinetRoute,
  beforeLoad: async ({ context: { user } }) => {
    await routerRBACGuard(user, 'canAccessAdminSettings');

    return { page: 'settings' };
  },
});

export const settingsTabs = [
  'global',
  'smtp',
  'logo',
  'manual',
  'discount',
] as const;

const SettingsPageSearchSchema = v.object({
  tab: v.enum(settingsTabs).catch('global'),
});

export type SettingsPageSearchSchemaType = v.infer<
  typeof SettingsPageSearchSchema
>;

export const settingsRoute = new Route({
  path: '/',
  getParentRoute: () => settingsRoutesRoot,
  component: () => <SettingsPage />,

  loader: async () => {
    const settings = await typedAxios.globalSettings.getGlobalSettings();
    const discounts = await typedAxios.discount.getDiscountsCleaning();

    return { settings, discounts };
  },
  validateSearch: (searchObj) => {
    return SettingsPageSearchSchema.parse(searchObj);
  },
});

export const settingsRoutesRootTree = settingsRoutesRoot.addChildren([
  settingsRoute,
]);
