import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Stack, useBoolean } from '@chakra-ui/react';
import Input from '@/components/Fields/Input';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { useNavigate, useParams } from '@tanstack/react-router';
import {
  addExhibitionAccountRoute,
  editExhibitionAccountRoute,
} from '@/features/Routing/protected/admin-exhibitions';
import {
  CompanyFormSchemaType,
  NewCompanyFormSchema,
  UpdateCompanyFormSchema,
} from '@/pages/AdminPanel/PersonalAccounts/MainInfoTab/types';
import {
  FormControlWrapper,
  ParticipantCheckContainer,
  ParticipantCheckWrapper,
} from '@/pages/AdminPanel/PersonalAccounts/MainInfoTab/styled';
import { useSearchCompanyByEmployee } from '@/pages/AdminPanel/PersonalAccounts/MainInfoTab/hooks/use-search-company-by-employee';
import { useCompanySave } from '@/pages/AdminPanel/PersonalAccounts/MainInfoTab/hooks/use-company-save';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';
import { Checkbox } from '@/components/Fields/Checkbox';
import { isNil } from '@/utils/is-nil';
import { AsyncButton } from '@/components/AsyncButton';
import { OptionType } from '@/model/common';
import { ITEPortal_Domain_Dto_CatalogExhibitorSummary } from '@/api';

interface MainInfoProps {
  type: string;
}

export const MainInfo = ({ type }: MainInfoProps): JSX.Element => {
  const editForm = type === 'edit';
  const { t } = useTranslation();
  const { exhibitionId, accountId } = useParams({ strict: false });
  const formInstance = useForm<CompanyFormSchemaType>({
    resolver: zodResolver(
      editForm ? UpdateCompanyFormSchema : NewCompanyFormSchema
    ),
  });
  const isAgentSelectedForExhibitor = formInstance.watch('agent');
  const navigate = useNavigate();
  const [selectedCompanyOption, setSelectedCompany] =
    useState<OptionType<ITEPortal_Domain_Dto_CatalogExhibitorSummary> | null>(
      null
    );
  const { technicalManagers, offices, companies, agents } = (
    editForm ? editExhibitionAccountRoute : addExhibitionAccountRoute
  ).useLoaderData();

  const { data: accountData } = useSuspenseQuery(
    QueryOptions.exhibitorQueryOptions({
      exhibitionId: +exhibitionId,
      exhibitorId: accountId ? +accountId : undefined,
    })
  );

  const [isAgentCreating, isAgentCreatingApi] = useBoolean(
    isNil(accountData.isAgent) ? false : accountData.isAgent
  );

  useEffect(
    function watchFormToToggleUXFlags() {
      formInstance.watch((value, { name }) => {
        if (name === 'isAgent') {
          isAgentCreatingApi[value.isAgent ? 'on' : 'off']();
        }

        if (name === 'companyName') {
          setSelectedCompany(
            // @ts-expect-error update types
            value.companyName || null
          );
        }
      });
    },
    [formInstance]
  );

  const { findCompanyByEmployee, isCompanyFoundByEmployee } =
    useSearchCompanyByEmployee(formInstance);
  const { saveCompany } = useCompanySave(formInstance);

  useEffect(
    function setValuesFromLoadedModel() {
      formInstance.reset({
        id: accountData.id,
        phoneNumber: accountData.phoneNumber || undefined,
        // @ts-expect-error desc
        sellingOfficeId: offices.find(
          (officeOption) => officeOption?.value === accountData.sellingOfficeId
        ),
        // @ts-expect-error desc
        technicalManagerEmail: technicalManagers.find(
          (managerOption) =>
            managerOption?.value === accountData.technicalManagerEmail
        ),
        isAgent: accountData.isAgent,
        agent: accountData.agent
          ? {
              value: accountData.agent.id,
              label: accountData.agent.name,
              // @ts-expect-error update types
              meta: accountData,
            }
          : undefined,
      });
    },
    [accountData]
  );

  const toAccounts = () => {
    navigate({
      to: '/admin/exhibitions/$exhibitionId/view/personal-accounts',
      replace: true,
      params: {
        exhibitionId,
      },
    });
  };

  useEffect(
    function toggleFormIsAgentCheckboxBasedOnSelectedCompany() {
      if (editForm) {
        return;
      }

      if (selectedCompanyOption?.meta) {
        formInstance.setValue('isAgent', selectedCompanyOption.meta.isAgent);
      } else if (!selectedCompanyOption) {
        formInstance.setValue('isAgent', false);
      }
    },
    [selectedCompanyOption]
  );

  const isAgentCheckboxDisabled = useMemo(() => {
    if (editForm) {
      return true;
    }

    if (isCompanyFoundByEmployee) {
      return true;
    }

    if (selectedCompanyOption) {
      return !selectedCompanyOption.__isNew__;
    }

    if (isAgentSelectedForExhibitor && isAgentSelectedForExhibitor.value) {
      return true;
    }

    return false;
  }, [
    editForm,
    isCompanyFoundByEmployee,
    selectedCompanyOption,
    isAgentSelectedForExhibitor,
  ]);

  const filteredCompanies = useMemo(() => {
    if (isAgentCreating) {
      return companies.filter((opt) => opt.meta?.isAgent);
    }

    if (isAgentSelectedForExhibitor) {
      return companies.filter((opt) => !opt.meta?.isAgent);
    }

    return companies;
  }, [companies, isAgentCreating, isAgentSelectedForExhibitor]);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={formInstance.handleSubmit(saveCompany)}>
        <Stack spacing="5">
          <FormControlWrapper>
            {!editForm && (
              <ParticipantCheckWrapper isRequired>
                <FormLabel>{t('participant-email')}</FormLabel>
                <ParticipantCheckContainer>
                  <Input<CompanyFormSchemaType>
                    maxWidth="100%"
                    name="employeeEmail"
                    disabled={editForm}
                    isRequired
                  >
                    <AsyncButton
                      colorScheme="red"
                      type="button"
                      onClick={findCompanyByEmployee}
                      disabled={editForm}
                      minW="fit-content"
                    >
                      {t('find-company-by-employee')}
                    </AsyncButton>
                  </Input>
                </ParticipantCheckContainer>
              </ParticipantCheckWrapper>
            )}

            {!editForm && (
              <Input<CompanyFormSchemaType>
                label={t('employee-name')}
                maxWidth="unset"
                name="employeeName"
                isRequired
              />
            )}

            {editForm ? (
              <>
                <Input<CompanyFormSchemaType>
                  label={t('company')}
                  name="companyName"
                  maxWidth="unset"
                  value={accountData.name || undefined}
                  disabled
                />
              </>
            ) : (
              <SelectComponent<CompanyFormSchemaType>
                name="companyName"
                allowCreate
                isRequired
                disabled={isCompanyFoundByEmployee}
                label={t('company')}
                options={filteredCompanies}
              />
            )}
            <Checkbox<CompanyFormSchemaType>
              name="isAgent"
              isDisabled={isAgentCheckboxDisabled}
            >
              {t('agent')}
            </Checkbox>
            <SelectComponent<CompanyFormSchemaType>
              label={t('sales-office')}
              options={offices}
              name="sellingOfficeId"
            />
            <SelectComponent<CompanyFormSchemaType>
              label={t('technical-managers')}
              options={technicalManagers}
              disabled={editForm}
              isRequired
              name="technicalManagerEmail"
            />
            <Input<CompanyFormSchemaType>
              label={t('phone')}
              maxWidth="unset"
              name="phoneNumber"
            />
            <SelectComponent<CompanyFormSchemaType>
              label={t('choose-agent')}
              options={agents}
              disabled={isAgentCreating}
              name="agent"
            />
          </FormControlWrapper>
          <ButtonGroup gap="1">
            <Button
              colorScheme="red"
              type="submit"
              isDisabled={formInstance.formState.isSubmitting}
              isLoading={formInstance.formState.isSubmitting}
            >
              {t('save')}
            </Button>
            <Button
              colorScheme="secondary"
              type="reset"
              onClick={toAccounts}
              isDisabled={formInstance.formState.isSubmitting}
            >
              {t('cancel')}
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
    </FormProvider>
  );
};
