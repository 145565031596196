import { CompanyFormSchemaType } from '@/pages/AdminPanel/PersonalAccounts/MainInfoTab/types';
import { useFormErrorsHandler } from '@/hooks/useFormErrorsHandler';
import { useNavigate, useParams } from '@tanstack/react-router';
import { UseFormReturn } from 'react-hook-form';
import { typedAxios } from '@/api/typed-axios';
import { ApiError, ITEPortal_Domain_Dto_CreateExhibitorDto } from '@/api';
import { isNil } from '@/utils/is-nil';
import { toast } from 'react-toastify';
import i18n from '@/i18n';
import { queryClient } from '@/features/query';
import { QueryOptions } from '@/features/query/query-options';

export const useCompanySave = (
  formInstance: UseFormReturn<CompanyFormSchemaType>
) => {
  const { processErrorsArray } = useFormErrorsHandler(formInstance);
  const { exhibitionId } = useParams({ strict: false });
  const navigate = useNavigate();

  const saveCompany = async (values: CompanyFormSchemaType) => {
    const id = values.id;
    const isEdit = !isNil(id);

    try {
      if (isEdit && !isNil(id)) {
        await typedAxios.exhibitor.putExhibitors({
          id: id,
          requestBody: {
            phoneNumber: values.phoneNumber,
            sellingOfficeId: +values.sellingOfficeId.value,
            agentId: values.agent?.value || null,
            exhibitionId: +exhibitionId,
          },
        });

        await queryClient.refetchQueries({
          queryKey: QueryOptions.exhibitorQueryOptions({
            exhibitionId: +exhibitionId,
            exhibitorId: id,
          }).queryKey,
        });
      } else {
        const body: ITEPortal_Domain_Dto_CreateExhibitorDto = {
          exhibitionId: +exhibitionId,
          sellingOfficeId: +values.sellingOfficeId.value,
          technicalManagerEmail: values.technicalManagerEmail.value,
          exhibitorEmployees: [
            {
              exhibitionId: +exhibitionId,
              name: values.employeeName,
              email: values.employeeEmail,
            },
          ],
          phoneNumber: values.phoneNumber,
          isAgent: !isNil(values.isAgent) ? values.isAgent : undefined,
          agentId: values.agent?.value || null,
        };

        if (values.companyName.__isNew__) {
          body.name = values.companyName.value;
        } else {
          body.exhibitorId = +values.companyName.value;
          body.name = values.companyName.label;
        }

        const savedId = await typedAxios.exhibitor.postExhibitors({
          requestBody: body,
        });

        navigate({
          to: `/admin/exhibitions/${exhibitionId}/view/personal-accounts/${savedId}/edit`,
          replace: true,
        });
      }

      toast.success(i18n.t('saved'));
    } catch (error: any) {
      processErrorsArray(error);
      console.dir(error);

      if (error instanceof ApiError && typeof error.body === 'string') {
        toast.error(error.body);
      }
    }
  };

  return {
    saveCompany,
  };
};
