import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { Card } from '@/components/Card/Card';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from '../Buttons/Button/Button';
import {
  BuildingDescriptionSection,
  ImageSection,
  ModalPrices,
  NoteSection,
  TableSection,
} from '@/components/DevelopmentCard/BuildingCardModal';
import { AmountCounter } from '@/components/AmountCounter';
import { useForm } from 'react-hook-form';
import { BuildingCardWrapper } from '@/components/DevelopmentCard/DevelopmentCard.styled';
import styled from 'styled-components';
import { OrderItem } from '@/model/IOrderItem';
import { Product } from '@/model/IProduct';
import { useStand } from '@/store/effector/stand';
import { orderApi } from '@/store/effector/order';
import { typedAxios } from '@/api/typed-axios';
import { useExhibition } from '@/store/effector/exhibition';
import { ITEPortal_Contracts_Enums_BuildingType } from '@/api';

export type CardPricesType = {
  current?: number;
  transition?: TransitionType[];
};

type TransitionType = {
  label: string;
  price: number;
};

type TableRowType = string[];

export type TableDataType = {
  titles: string[];
  rows: TableRowType[];
};

export type CardInfoType = {
  prices?: CardPricesType;
  imageDescription?: string | null;
  buildingLabel?: string | null;
  buildingDescription?: string | null;
  tableData?: TableDataType;
  note?: string | null;
  images?: string[] | null;
};

interface DevelopmentCardProps {
  header?: ReactNode;
  cardName: string;
  cardInfo?: CardInfoType;
  product: Product;
  isSelected: boolean;
}

const StyledCard = styled(Card)`
  .chakra-card__header {
    padding-bottom: 0;
  }

  .chakra-card__body {
    padding-top: 1rem;
    padding-bottom: 0;
  }
`;

export const DevelopmentCard = ({
  header,
  children,
  cardInfo,
  product,
  isSelected,
  ...props
}: PropsWithChildren<DevelopmentCardProps>) => {
  const { standForOrder } = useStand();
  const { exhibitionId, sellingOfficeId } = useExhibition();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openConfirm, setOpenConfirm] = useState(false);
  const { handleSubmit } = useForm();
  const { t } = useTranslation();
  const suffix = t('sqm');

  const onSubmit = handleSubmit(() => {
    setOpenConfirm(!openConfirm);
  });

  const selectBuilding = useCallback(async () => {
    if (product) {
      try {
        const buildingType = Math.max(...(product.buildingTypes || []));

        const priceListItemsBasedOnBuildingType =
          await typedAxios.priceListItem.getPricelistItems({
            exhibitionId: +exhibitionId,
            sellingOfficeId: +sellingOfficeId,
            buildingType:
              +buildingType as ITEPortal_Contracts_Enums_BuildingType,
            categoryIds: product.categoryId?.toString(),
            productId: product.productId,
          });

        const building = priceListItemsBasedOnBuildingType.find(
          (pi) => pi.productName === product.productName
        );

        if (building && building.includedPriceListItems) {
          product.setLinkedItems(building.includedPriceListItems);
        }
      } catch {}

      orderApi.clearProducts();
      orderApi.addItem(
        OrderItem.fromJSON({
          id: product.getProductId(),
          product,
          amount: standForOrder?.area,
        })
      );
    }
  }, []);

  const setBuilding = async () => {
    await selectBuilding();
  };

  const ModalConfirm = () => {
    const closeModal = () => setOpenConfirm(false);
    const confirmTransition = () => setBuilding();

    return (
      <Modal isOpen={openConfirm} onClose={closeModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('building-selection-confirmation')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t('building-confirm', { buildingName: header })}
          </ModalBody>
          <ModalFooter onClick={closeModal}>
            <ButtonGroup gap="1">
              <Button colorScheme="secondary" onClick={confirmTransition}>
                {t('yes')}
              </Button>
              <Button colorScheme="black" onClick={closeModal}>
                {t('cancel')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const ModalInfoComponent = (): JSX.Element => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ModalPrices prices={cardInfo?.prices} header={header} />
            <ImageSection description={cardInfo?.imageDescription}>
              {children}
            </ImageSection>
            <BuildingDescriptionSection
              buildingLabel={cardInfo?.buildingLabel}
              buildingDescription={cardInfo?.buildingDescription}
            />
            {cardInfo?.tableData && (
              <TableSection tableData={cardInfo?.tableData} />
            )}
            {cardInfo?.note && <NoteSection note={cardInfo?.note} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const SelectionButton = () => {
    const changeHandler = (value: number) => {
      if (product && product instanceof Product) {
        orderApi.changeItem({
          id: product.getProductId(),
          product,
          amount: value,
        });
      }
    };

    return isSelected ? (
      <AmountCounter
        amountCounterValue={standForOrder?.area}
        onChange={(counterValue: number) => changeHandler(counterValue)}
        disabled={true}
        suffix={suffix}
        precision={2}
        step={0.25}
      />
    ) : (
      <Button colorScheme="secondary" width="90px">
        {t('choose')}
      </Button>
    );
  };

  const BuildingInfoButton = (): JSX.Element => {
    return (
      <>
        <Button colorScheme="black" width="110px" onClick={onOpen}>
          {t('moreDetails')}
        </Button>
        <ModalInfoComponent />
        <ModalConfirm />
      </>
    );
  };

  return (
    <StyledCard
      header={header}
      footer={
        <BuildingCardWrapper onSubmit={onSubmit}>
          <ButtonGroup spacing="2">
            <BuildingInfoButton />
            <SelectionButton />
          </ButtonGroup>
        </BuildingCardWrapper>
      }
      {...props}
    >
      {children}
    </StyledCard>
  );
};
