import dayjs from 'dayjs';

export const DateUtils = {
  isDate: (date: unknown): date is Date => {
    return date instanceof Date && !isNaN(date.getTime());
  },
  format(date: string, format = 'DD.MM.YYYY HH:mm'): string {
    return dayjs(date).format(format);
  },
  sortByDateAddedDescending: <T extends { createdAt?: string | null }>(
    array: Array<T>
  ) => {
    return array.sort((a, b) => {
      const dateA = dayjs(a.createdAt);
      const dateB = dayjs(b.createdAt);

      if (dateA.isAfter(dateB)) {
        return -1;
      } else if (dateA.isBefore(dateB)) {
        return 1;
      } else {
        return 0;
      }
    });
  },
  findLatestDate: (dates: Array<string | null | undefined>): string | null => {
    if (dates.length === 0) {
      return null;
    }

    let latestDate: dayjs.Dayjs | null = null;

    for (const dateString of dates.filter(Boolean)) {
      const date = dayjs(dateString);

      // Check if date is valid
      if (!date.isValid()) {
        continue;
      }

      if (!latestDate || date.isAfter(latestDate)) {
        latestDate = date;
      }
    }

    return latestDate ? latestDate.format('DD.MM.YYYY') : null;
  },
};
