import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { DatePickerDays, DatePickerMonths } from '@/utils/constants';
import { FieldProps } from '@/components/Fields/interfaces';
import {
  FormErrorMessage,
  Box,
  FormControl,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import {
  Controller,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CloseIcon } from '@chakra-ui/icons';

interface DatePickerProps<T extends FieldValues> extends FieldProps<T> {
  defaultValue?: Date;
  dateFormat?: string;
  width?: string | number;
  errors?: any;
  clearable?: boolean;
}

export const DatePicker = <T extends FieldValues = FieldValues>({
  dateFormat = 'dd.MM.yyyy',
  // @ts-expect-error as default string doesn't found in generic T
  name = 'date-picker',
  label,
  isRequired,
  control,
  defaultValue,
  clearable,
}: DatePickerProps<T>) => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error(
      `This field (${name}) should be used only within FormContext`
    );
  }

  const { fieldState } = useController({
    name: name,
  });

  if (!fieldState) {
    throw new Error(
      'fieldState not found. Is this field used within FormContext?'
    );
  }

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const defaultDate = defaultValue
    ? new Date(dayjs(defaultValue).toString())
    : undefined;

  return (
    <Box width="100%">
      <FormControl
        isInvalid={fieldState.invalid}
        isRequired={isRequired}
        display="felx"
        flexDirection="column"
      >
        {label && <FormLabel>{label}</FormLabel>}
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            let fieldValue = field.value;

            if (typeof field.value === 'string') {
              // @ts-expect-error desc
              fieldValue = new Date(dayjs(field.value).toString());
            }

            return (
              <InputGroup>
                <SingleDatepicker
                  usePortal
                  date={fieldValue || defaultDate}
                  onDateChange={(date: Date) => {
                    field.onChange(date);
                  }}
                  configs={{
                    dateFormat: dateFormat,
                    dayNames: DatePickerDays,
                    monthNames: DatePickerMonths,
                  }}
                />
                {clearable && (
                  <InputRightElement>
                    <IconButton
                      aria-label="Clear"
                      variant="ghost"
                      size="xs"
                      icon={<CloseIcon color="hsl(0, 0%, 80%)" />}
                      onClick={() => {
                        field.onChange(null);
                      }}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            );
          }}
        />
        {fieldState.error?.message && (
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};
