import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as v from 'zod';

import React, { useEffect } from 'react';
import { useNameTranslate } from '../../../hooks/useStringName';
import { Button, FormControl, Stack } from '@chakra-ui/react';
import { UploadFileDropzone } from '../../../components/FileUpload/FileUpload';
import styled from 'styled-components';
import Input from '../../../components/Fields/Input';
import { FormLabel } from '../../../components/FormLabel/FormLabel';
import { typedAxios } from '../../../api/typed-axios';
import { settingsRoute } from '../../../features/Routing/protected/settings';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type ManualUploaderFormValues = {
  manualFileEn?: Blob[];
  manualLinkEn?: string | null;
  manualFileRu?: Blob[];
  manualLinkRu?: string | null;
};

const Schema = v.object({
  manualFileEn: v.array(v.instanceof(File)),
  manualLinkEn: v.string().url('Should be valid URL'),
  manualFileRu: v.array(v.instanceof(File)),
  manualLinkRu: v.string().url('Should be valid URL'),
});

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
`;

const ControlLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 10px;

  > div:first-of-type {
    width: 70%;
  }
`;

export const ManualUploaderTab = () => {
  const { t } = useTranslation();
  const instance = useForm<ManualUploaderFormValues>({
    resolver: zodResolver(Schema),
  });

  const loaderData = settingsRoute.useLoaderData();

  useEffect(
    function setFormValuesOfLoadedModel() {
      instance.reset({
        manualLinkRu: loaderData.settings.exhibitorManualRu,
        manualLinkEn: loaderData.settings.exhibitorManualEn,
      });
    },
    [loaderData]
  );

  const handleSaveSetting =
    (scope: keyof ManualUploaderFormValues, locale: 'en' | 'ru') =>
    async () => {
      const valid = await instance.trigger(scope);
      const value = instance.getValues(scope);

      if (!valid) {
        return;
      }

      switch (true) {
        case scope.includes('manualLink') && typeof value === 'string':
          typedAxios.globalSettings
            .postGlobalSettingsEditExhibitorManualUrl({
              culture: locale,
              url: value,
            })
            .then(() => {
              toast.success(t('user-manual-updated'));
            })
            .catch(() => {
              toast.error(t('user-manual-update-failed'));
            });

          break;

        case scope.includes('manualFile') && value && value[0] instanceof Blob:
          typedAxios.globalSettings
            .postGlobalSettingsEditExhibitorManualFile({
              culture: locale,
              formData: {
                manual: value[0],
              },
            })
            .then(() => {
              toast.success(t('user-manual-updated'));
            })
            .catch(() => {
              toast.error(t('user-manual-update-failed'));
            });

          break;
      }
    };

  return (
    <FormProvider {...instance}>
      <Stack spacing="5">
        <FormControlWrapper>
          <ControlLine>
            <UploadFileDropzone<ManualUploaderFormValues>
              label={`${useNameTranslate('participantManual')} (EN)`}
              name="manualFileEn"
              multiple={false}
              accept={{
                'application/pdf': ['.pdf'],
              }}
            />
            <Button
              colorScheme="red"
              width="100px"
              onClick={handleSaveSetting('manualFileEn', 'en')}
            >
              {useNameTranslate('save')}
            </Button>
          </ControlLine>

          <ControlLine>
            <FormControlWrapper>
              <FormLabel>
                {useNameTranslate('linkToParticipantManual')} (EN)
              </FormLabel>
              <Input<ManualUploaderFormValues>
                name="manualLinkEn"
                maxWidth="100%"
              />
            </FormControlWrapper>
            <Button
              colorScheme="red"
              width="100px"
              onClick={handleSaveSetting('manualLinkEn', 'en')}
            >
              {useNameTranslate('save')}
            </Button>
          </ControlLine>

          <ControlLine>
            <UploadFileDropzone<ManualUploaderFormValues>
              label={`${useNameTranslate('participantManual')} (RU)`}
              name="manualFileRu"
              multiple={false}
              accept={{
                'application/pdf': ['.pdf'],
              }}
            />
            <Button
              colorScheme="red"
              width="100px"
              onClick={handleSaveSetting('manualFileRu', 'ru')}
            >
              {useNameTranslate('save')}
            </Button>
          </ControlLine>

          <ControlLine>
            <FormControlWrapper>
              <FormLabel>
                {useNameTranslate('linkToParticipantManual')} (RU)
              </FormLabel>
              <Input<ManualUploaderFormValues>
                name="manualLinkRu"
                maxWidth="100%"
              />
            </FormControlWrapper>
            <Button
              colorScheme="red"
              width="100px"
              onClick={handleSaveSetting('manualLinkRu', 'ru')}
            >
              {useNameTranslate('save')}
            </Button>
          </ControlLine>
        </FormControlWrapper>
      </Stack>
    </FormProvider>
  );
};
