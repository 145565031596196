import { FieldValues, useForm, FormProvider } from 'react-hook-form';
import {
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  Stack,
} from '@chakra-ui/react';
import Input from '@/components/Fields/Input';
import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import { TextArea } from '@/components/TextArea/TextArea';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { toast } from 'react-toastify';

import unitsOfMeasurement from '@/i18n/lang/unit-of-measurement/en.json';
import { Checkbox } from '@/components/Fields/Checkbox';
import { typedObjectKeys } from '@/utils/typed-object-keys';
import { Strict } from '@/types/support-types';
import { OptionType } from '@/model/common';
import { typedAxios } from '@/api/typed-axios';
import { ITEPortal_Domain_Dto_CategoryDto } from '@/api';
import { isNil } from '@/utils/is-nil';
import { TKey } from '@/i18n/types';
import { useTranslation } from 'react-i18next';
import { useParentCategoriesLoader } from '@/pages/AdminPanel/ServiceDirectory/EditCategoryForm/use-parent-categories-loader';
import { isOption } from '@/utils/is-option';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface EditEntityModalProps<T> {
  openConfirm: boolean;
  closeModal: () => void;
  refresh: () => void;
  editTableData: Required<T> | undefined;
  parentCategoryName: ITEPortal_Domain_Dto_CategoryDto['name'];
}

const defaultValues = {
  id: null,
  displayNameRu: null,
  displayNameEn: null,
  parentCategoryName: null,
  imageUrl: null,
  descriptionRu: null,
  descriptionEn: null,
};

type FormValues = Omit<ITEPortal_Domain_Dto_CategoryDto, 'unitOfMeasure'> & {
  displayNameRu: string;
  displayNameEn: string;
  descriptionRu: string;
  descriptionEn: string;
  unitOfMeasure: OptionType;
  isDecimal: boolean;
  parentCategoryName?: ITEPortal_Domain_Dto_CategoryDto['name'];
};

export const EditCategoryForm = <
  T extends Strict<ITEPortal_Domain_Dto_CategoryDto>
>({
  openConfirm,
  closeModal,
  refresh,
  editTableData,
}: EditEntityModalProps<T>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editData, setEditData] = useState<any>();
  const isEdit = Boolean(editTableData);
  const { t } = useTranslation();

  const { data: parentCategories = [] } = useParentCategoriesLoader();

  useEffect(
    function prepareEditData() {
      if (!editTableData) {
        setEditData(defaultValues);
      }

      if (editTableData) {
        const fetchData = async () => {
          const categoryID = editTableData.name;

          if (isNil(categoryID)) {
            return;
          }

          setIsSubmitting(true);
          try {
            const data = await typedAxios.category.getCategoriesResources({
              name: categoryID,
            });

            const editDataWithResources: FormValues = {
              id: editTableData.id,
              name: editTableData.name,
              imageUrl: editTableData.imageUrl,
              parentCategoryName: editTableData.parentCategoryId,
              displayNameRu: data?.ru?.name || '',
              displayNameEn: data?.en?.name || '',
              descriptionRu: data?.ru?.description || '',
              descriptionEn: data?.en?.description || '',
              isDecimal: editTableData.isDecimal,
              unitOfMeasure: {
                value: editTableData.unitOfMeasure,
                label: t(editTableData.unitOfMeasure as TKey),
              },
            };

            typedObjectKeys(editDataWithResources).forEach((field) => {
              methods.setValue(field, editDataWithResources[field]);
            });

            setEditData(editDataWithResources);
          } catch (error: any) {
            const fieldErrors = error?.response?.data?.errors;

            if (fieldErrors) {
              toast.error(fieldErrors);
            }
          } finally {
            setIsSubmitting(false);
          }
        };

        fetchData();
      }
    },
    [editTableData]
  );

  useEffect(
    function setFormValues() {
      methods.reset({
        ...editData,
      });
    },
    [editData]
  );

  const methods = useForm<FormValues>({
    mode: 'onBlur',
  });

  const unitOptions = [
    {
      value: '',
      label: '',
    },
    ...typedObjectKeys(unitsOfMeasurement).map((unit) => {
      return {
        value: unit,
        label: t(unit),
      };
    }),
  ];

  const submitHandler = async (values: FieldValues) => {
    const method = editTableData ? 'put' : 'post';

    try {
      setIsSubmitting(true);

      console.log(values);

      await typedAxios.category[`${method}Categories`]({
        requestBody: {
          name: values.name,
          isDecimal: !!values?.isDecimal,
          unitOfMeasure: values?.unitOfMeasure?.value,
          parentCategoryName: isOption(values.parentCategoryName)
            ? values.parentCategoryName.value
            : values.parentCategoryName,
          resources: [
            {
              key: 'name',
              value: values?.displayNameRu || '',
              cultureName: 'ru',
            },
            {
              key: 'name',
              value: values?.displayNameEn || '',
              cultureName: 'en',
            },
            {
              key: 'description',
              value: values?.descriptionRu || '',
              cultureName: 'ru',
            },
            {
              key: 'description',
              value: values?.descriptionEn || '',
              cultureName: 'en',
            },
          ],
        },
      });

      if (values?.['imageUrl'] && typeof values?.['imageUrl'][0] !== 'string') {
        typedAxios.category.postCategoriesImage({
          name: values.name,
          formData: {
            image: values?.['imageUrl'][0],
          },
        });
      }

      toast.success(t('saved'));
      closeModal();
      refresh();
    } catch (error: any) {
      // processErrorsObject(error);
      toast.error(t('error-occurred'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer isOpen={openConfirm} onClose={closeModal} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          {t(isEdit ? 'edit-category' : 'new-category')}
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <FormProvider {...methods}>
            <form>
              <Stack spacing="5">
                <FormControlWrapper>
                  <Input<FormValues>
                    label="ID"
                    maxWidth="100%"
                    name="name"
                    disabled={!!editTableData}
                    isRequired
                  />
                  <Input<FormValues>
                    label={`${t('category-name')} (RU)`}
                    maxWidth="100%"
                    name="displayNameRu"
                    isRequired
                  />
                  <Input<FormValues>
                    label={`${t('category-name')} (EN)`}
                    maxWidth="100%"
                    name="displayNameEn"
                    isRequired
                  />
                  <SelectComponent<FormValues>
                    label={t('parent-category-name')}
                    // @ts-expect-error desc
                    options={parentCategories}
                    name="parentCategoryName"
                  />
                  <SelectComponent<FormValues>
                    name="unitOfMeasure"
                    options={unitOptions}
                    label={t('unit-of-measurement')}
                  />
                  <Checkbox<FormValues> name="isDecimal">
                    {t('decimal-values')}
                  </Checkbox>
                  <UploadFileDropzone<FormValues>
                    label={t('upload-image')}
                    name="imageUrl"
                    defaultValue={editData?.['imageUrl']}
                  />
                  <TextArea<FormValues>
                    label={`${t('category-description')} (RU)`}
                    name="descriptionRu"
                  />
                  <TextArea<FormValues>
                    label={`${t('category-description')} (EN)`}
                    name="descriptionEn"
                    defaultValue={editData?.['descriptionEn']}
                  />
                </FormControlWrapper>
              </Stack>
            </form>
          </FormProvider>
        </DrawerBody>
        <DrawerFooter justifyContent="center">
          <ButtonGroup gap="1" justifyContent="center">
            <OrderButton
              colorScheme="red"
              onClick={methods.handleSubmit(submitHandler)}
              disabled={isSubmitting}
            >
              {t('save')}
            </OrderButton>
            <OrderButton colorScheme="secondary" onClick={closeModal}>
              {t('cancel')}
            </OrderButton>
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
