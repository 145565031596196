import { Route } from '@tanstack/react-router';
import { cabinetRoute } from '../protected';
import { ServiceDirectory } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion';

import { TKey } from '@/i18n/types';
import { SearchBoxOptionsType } from '@/components/AdminSearch/types';
import i18n from '@/i18n';
import { Button, Flex } from '@chakra-ui/react';
import { editCategoryApi } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/edit-category-api';
import { routerRBACGuard } from '@/features/Routing/guard';
import { editProductApi } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useProductForm';
import { queryClient } from '@/features/query';
import { QueryOptions } from '@/features/query/query-options';

export const serviceDictionaryRoutesRoot = new Route({
  path: '/service-dictionary',
  getParentRoute: () => cabinetRoute,
  beforeLoad: async ({ context: { user } }) => {
    await routerRBACGuard(user, 'canAccessAdminServiceDirectory');

    return { page: 'service-dictionary' satisfies TKey } as const;
  },
});

export const serviceDictionaryRoute = new Route({
  path: '/',
  getParentRoute: () => serviceDictionaryRoutesRoot,
  component: () => <ServiceDirectory />,
  beforeLoad: async (opts) => {
    queryClient.ensureQueryData(QueryOptions.loadCategoryOptions());

    if (!opts.search.tab || opts.search.tab === 'categories') {
      return {
        key: 'categories',
        searchOptions: [
          {
            placeholder: i18n.t('search-categories'),
          } satisfies SearchBoxOptionsType,
          {
            placeholder: i18n.t('search-services'),
            queryParameterName: 'searchProduct',
          } satisfies SearchBoxOptionsType,
        ],
        subHeader: (
          <Flex flexDirection="row" ml="auto" gap={4}>
            <Button
              ml="auto"
              onClick={() => {
                editCategoryApi.open({
                  category: null,
                  parentCategoryName: undefined,
                });
              }}
            >
              {i18n.t('add-category')}
            </Button>
            <Button
              ml="auto"
              onClick={() => {
                editProductApi.open({
                  product: null,
                });
              }}
            >
              {i18n.t('add-service')}
            </Button>
          </Flex>
        ),
      };
    }

    if (opts.search.tab && opts.search.tab === 'services') {
      return {
        key: 'services',
        searchOptions: [
          {
            placeholder: i18n.t('search-services'),
          } satisfies SearchBoxOptionsType,
        ],
      };
    }
  },
  validateSearch: (
    searchObj: Record<string, unknown>
  ): {
    tab: 'categories' | 'services' | undefined;
    action: 'create-category' | undefined;
  } => {
    return {
      tab: ['categories', 'services'].includes(searchObj.tab as string)
        ? (searchObj.tab as 'categories' | 'services')
        : undefined,

      action: ['create-category'].includes(searchObj.action as string)
        ? (searchObj.action as 'create-category')
        : undefined,
    };
  },
});

export const serviceDictionaryRoutesRootTree =
  serviceDictionaryRoutesRoot.addChildren([serviceDictionaryRoute]);
