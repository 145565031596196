import { User } from '@/model/IUser';
import { useAuth } from '@/store/effector/auth';

export const rules = {
  canManageSuperAdmins: (user) => {
    return user.isSuperAdmin;
  },
  canDeleteOrder: (user) => {
    return user.isSuperAdmin || user.isTechnicalManager;
  },
  canCreateOrder: (user) => {
    return user.isSuperAdmin || user.isTechnicalManager || user.isAgent;
  },
  canEditOrders: (user) => {
    return !user.isModerator && !user.isExhibitor;
  },
  canViewPublicExhibitions: (user) => {
    return user.isExhibitor || user.isAgent;
  },
  canAccessAdminUsers: (user) => {
    return user.isSuperAdmin || user.isAdmin;
  },
  canAccessAdminSettings: (user) => {
    return user.isSuperAdmin || user.isAdmin;
  },
  canAccessAdminServiceDirectory: (user) => {
    return user.isSuperAdmin || user.isSupportManager;
  },
  canAccessAdminCompanies: (user) => {
    return user.isSuperAdmin || user.isSupportManager;
  },
  canAccessAdminExhibitions: (user) => {
    return (
      user.isSuperAdmin ||
      user.isAgent ||
      user.isModerator ||
      user.isTechnicalManager ||
      user.isSupportManager
    );
  },
  canAccessExhibitionProfile: (user) => {
    return user.isAgent || user.isExhibitor;
  },
  canAccessExhibitionPersonalAccounts: (user) => {
    return !user.isExhibitor && !user.isAgent && !user.isSupportManager;
  },
  canAccessExhibitionOrders: (user) => {
    return (
      user.isSuperAdmin ||
      user.isAgent ||
      user.isTechnicalManager ||
      user.isModerator
    );
  },
  canAddExhibition: (user) => {
    return !user.isExhibitor && !user.isAgent && !user.isTechnicalManager;
  },
  canEditExhibition: (user) => {
    return !user.isAgent && !user.isModerator;
  },
  canManageExhibition: (user) => {
    return (
      user.isSuperAdmin ||
      user.isTechnicalManager ||
      user.isAgent ||
      user.isModerator
    );
  },
  canAddExhibitionExhibitor: (user) => {
    return user.isSuperAdmin || user.isTechnicalManager;
  },
  canAddStand: (user) => {
    return user.isSuperAdmin || user.isTechnicalManager;
  },
  canAddOrderPersonalDiscount: (user) => {
    return !user.isModerator && !user.isExhibitor && !user.isAgent;
  },
  canRemoveSurchargeFromOrder: (user) => {
    return !user.isModerator && !user.isExhibitor && !user.isAgent;
  },
  canChangeOrderStatus: (user) => {
    return !user.isModerator && !user.isExhibitor && !user.isAgent;
  },
} satisfies Record<string, (user: User) => boolean>;

export type RBACRulesType = keyof typeof rules;

export const checkRBACRule = (user: User | null) => (rule: RBACRulesType) => {
  if (!user) {
    return null;
  }

  return rules[rule]?.(user) || false;
};

export const useRBAC = () => {
  const { user } = useAuth();

  const guard = checkRBACRule(user);

  return {
    user,
    can: (rule: RBACRulesType) => {
      return guard(rule);
    },
  };
};
