import React, { useEffect } from 'react';
import { DevelopmentCard } from '@/components/DevelopmentCard';
import styled from 'styled-components';
import { t } from 'i18next';
import { LoaderWrapper } from '@/theme/css/styles';
import { Loader } from '@/components/Loader/Loader';
import { getSelectBuildingInfo } from '@/components/Forms/Services/SelectBuilding/getSelectBuildingInfo';
import { ShowMoreText } from '@/components/Forms/Services/TemporaryStaff';
import { createExhibitionOrderServiceRoute } from '../../../../features/Routing/protected/exhibitions-by-role';
import { CATEGORY_TYPES } from '../../../../constants/categorie';
import { typedAxios } from '../../../../api/typed-axios';
import { ProductUtils } from '@/utils/product-utils';
import { useExhibition } from '@/store/effector/exhibition';
import { useStand } from '@/store/effector/stand';
import { useOrder } from '@/store/effector/order';
import { loadingApi, useAppLoading } from '@/store/effector/loading';
import { ITEPortal_Domain_Dto_CategoryDto } from '../../../../api';
import { Box, Text } from '@chakra-ui/react';
import { useVatSettings } from '@/store/effector/vat-settings';
import { BuildingGallery } from '@/components/ImageGalleries/BuildingGallery';
import { useQuery } from '@tanstack/react-query';

const BuildingTypesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;

  & > div {
    flex-basis: 50%;
    max-width: calc(50% - 10px);
  }
`;

interface SelectBuildingCardProps {
  description?: ITEPortal_Domain_Dto_CategoryDto['description'];
}

const useBuildingsLoader = () => {
  const { exhibitionId } = createExhibitionOrderServiceRoute.useParams();
  const { standForOrder, originalBuildingType } = useStand();
  const { sellingOfficeId } = useExhibition();

  return useQuery({
    queryKey: ['buildings', exhibitionId, standForOrder, sellingOfficeId],
    queryFn: async () => {
      const data = await typedAxios.priceListItem.getPricelistItems({
        exhibitionId: +exhibitionId,
        sellingOfficeId: +sellingOfficeId,
        buildingType: standForOrder.buildingType || originalBuildingType,
      });

      const productItemCardData = data.filter(
        (item) => item.categoryName === CATEGORY_TYPES.SELECT_BUILDING
      );

      const productItemCardObjects = productItemCardData?.map((product) => {
        return ProductUtils.createProductFromPriceListItem(product);
      });

      const productItemsObjects = getSelectBuildingInfo(
        productItemCardObjects,
        productItemCardData
      );

      return productItemsObjects;
    },
  });
};

export const SelectBuilding = ({
  description,
}: SelectBuildingCardProps): JSX.Element => {
  const { items } = useOrder();
  const isLoader = useAppLoading();
  const { vatMessage } = useVatSettings();

  const { data: cardItems = [], isFetching } = useBuildingsLoader();

  useEffect(
    function toggleLoading() {
      if (isFetching) {
        loadingApi.on();
      } else {
        loadingApi.off();
      }
    },
    [isFetching]
  );

  return (
    <>
      {vatMessage && (
        <Box marginY={4}>
          <Text color="gray.500">{vatMessage}</Text>
        </Box>
      )}
      {description && !isLoader && (
        <ShowMoreText
          lines={2}
          keepNewLines={false}
          more={t('showMore')}
          less={t('showLess')}
          anchorClass="show-more-less-clickable"
        >
          {description}
        </ShowMoreText>
      )}
      {isLoader ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          {cardItems.length > 0 && (
            <BuildingTypesWrapper>
              {cardItems.map(({ key, text, cardInfo, product }) => {
                const isSelected = items?.some(
                  (oi) => oi.id === product.getProductId()
                );

                return (
                  <DevelopmentCard
                    header={text}
                    key={key}
                    cardName={key}
                    cardInfo={cardInfo}
                    product={product}
                    isSelected={isSelected}
                  >
                    {cardInfo?.images && (
                      <BuildingGallery images={cardInfo?.images} />
                    )}
                  </DevelopmentCard>
                );
              })}
            </BuildingTypesWrapper>
          )}
        </>
      )}
    </>
  );
};
