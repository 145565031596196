import { useNameTranslate } from '@/hooks/useStringName';
import {
  Box,
  Button as ChakraButton,
  ButtonGroup,
  FormControl,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@/components/Buttons/Button/Button';
import Input from '@/components/Fields/Input';
import { useNavigate, useParams } from '@tanstack/react-router';
import { DatePicker } from '@/components/DatePicker/DatePicker';
import styled from 'styled-components';
import { TextArea } from '@/components/TextArea/TextArea';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import fetchApi from '@/utils/fetchAPI';
import {
  CREATE_EXHIBITION,
  EDIT_EXHIBITION,
  SAVE_EXHIBITION_IMG,
} from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import dayjs from 'dayjs';
import {
  createExhibitionRoute,
  editExhibitionRoute,
} from '../../../features/Routing/protected/admin-exhibitions';
import { toast } from 'react-toastify';
import { useFormErrorsHandler } from '../../../hooks/useFormErrorsHandler';
import { typedAxios } from '../../../api/typed-axios';
import { typedObjectKeys } from '../../../utils/typed-object-keys';
import { isOption } from '../../../utils/is-option';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormValues, ValidationSchema } from './types';
import { DateUtils } from '../../../utils/date-utils';

const MarginContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const MarginWrapper = styled(FormControl)`
  min-width: 100%;
`;

interface ExhibitionDescriptionProps {
  type: 'add' | 'edit';
}

const dateFields = [
  'startDate',
  'endDate',
  'orderCreationLastDate',
  'firstMarginDate',
  'secondMarginDate',
  'thirdMarginDate',
] as const;

const isDateField = (name: string): name is (typeof dateFields)[number] => {
  // @ts-expect-error as we are asserting acceptable names
  return dateFields.includes(name);
};

export const ExhibitionDescription = ({
  type,
}: ExhibitionDescriptionProps): JSX.Element => {
  const { exhibitionId } = useParams({ strict: false });
  const editForm = type === 'edit';
  const { technicalManagers: managers } = (
    editForm ? editExhibitionRoute : createExhibitionRoute
  ).useLoaderData();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    resolver: zodResolver(ValidationSchema),
  });

  const { processErrorsArray } = useFormErrorsHandler(methods);

  const toExhibitions = () => {
    navigate({
      to: '/admin/exhibitions',
      replace: true,
    });
  };

  useEffect(() => {
    if (type === 'edit') {
      const fetchData = async () => {
        setIsSubmitting(true);
        try {
          const data = await typedAxios.exhibition.getExhibitions({
            id: +exhibitionId,
          });

          if (Array.isArray(data.technicalManagers)) {
            // @ts-expect-error desc
            data.technicalManagers = data.technicalManagers
              .map((managerValue) => {
                return managers.find(
                  (managerOption) => managerOption?.value === managerValue
                );
              })
              .filter(Boolean);
          }

          typedObjectKeys(data).forEach((field) => {
            // @ts-expect-error desc
            methods.setValue(field, data[field]);
          });
        } catch (error: any) {
          toast.error('Could not load exhibition');
        } finally {
          setIsSubmitting(false);
        }
      };

      fetchData();
    }
  }, []);

  const submitHandler = async (values: FormValues) => {
    typedObjectKeys(values).forEach((field) => {
      const value = values[field];

      if (isDateField(field) && DateUtils.isDate(value)) {
        values[field] = dayjs
          .tz(dayjs(value).format('YYYY-MM-DD'), 'Etc/GMT+0')
          .toDate();
      }
    });

    const method = editForm ? FetchMethods.Put : FetchMethods.Post;
    const link = editForm ? EDIT_EXHIBITION(exhibitionId) : CREATE_EXHIBITION;
    const technicalManagers = values.technicalManagers?.map((manager) =>
      typeof manager !== 'string' && isOption(manager) ? manager.value : manager
    );

    const data = {
      ...values,
      technicalManagers,
    };

    setIsSubmitting(true);
    try {
      const response = await fetchApi(link, {
        method: method,
        data,
      });

      const id = editForm ? exhibitionId : response?.data;

      if (data['upload-image']) {
        await fetchApi(
          SAVE_EXHIBITION_IMG(id),
          {
            method: FetchMethods.Post,

            data: { logo: data['upload-image'][0] },
          },
          { 'Content-Type': 'multipart/form-data' }
        );
      }

      toExhibitions();
    } catch (e: any) {
      processErrorsArray(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitHandler)}>
        <Stack spacing="5">
          <Input<FormValues>
            label={useNameTranslate('exhibition-name')}
            maxWidth="100%"
            name="name"
            isRequired
          />
          <UploadFileDropzone
            label={useNameTranslate('upload-image')}
            defaultValue={methods.getValues('logoUrl') || undefined}
            name="upload-image"
          />
          <DatePicker<FormValues>
            name="startDate"
            label={useNameTranslate('startDate')}
            isRequired
          />
          <DatePicker<FormValues>
            name="endDate"
            label={useNameTranslate('finDate')}
            isRequired
          />
          <DatePicker<FormValues>
            name="orderCreationLastDate"
            label={useNameTranslate('order-creation-last-date')}
            clearable
          />
          <Input<FormValues>
            label={useNameTranslate('location')}
            maxWidth="100%"
            name="place"
            isRequired
          />
          <TextArea<FormValues>
            label={useNameTranslate('exhibition-description')}
            name="description"
          />
          <SelectComponent<FormValues>
            label={useNameTranslate('technical-managers')}
            options={managers}
            closeMenuOnSelect={false}
            name="technicalManagers"
            isMulti
            isRequired
          />
          <MarginWrapper isRequired>
            <FormLabel>{useNameTranslate('margin1')}</FormLabel>
            <MarginContainer>
              <Input<FormValues>
                maxWidth="100%"
                name="firstMargin"
                addonRight="%"
              />
              <DatePicker<FormValues> name="firstMarginDate" />
            </MarginContainer>
          </MarginWrapper>
          <MarginWrapper isRequired>
            <FormLabel>{`${useNameTranslate('margin2')}`}</FormLabel>
            <MarginContainer>
              <Input<FormValues>
                maxWidth="100%"
                name="secondMargin"
                isRequired
                addonRight="%"
              />
              <DatePicker<FormValues> name="secondMarginDate" isRequired />
            </MarginContainer>
          </MarginWrapper>
          <MarginWrapper isRequired>
            <FormLabel>{`${useNameTranslate('margin3')}`}</FormLabel>
            <MarginContainer>
              <Input<FormValues>
                maxWidth="100%"
                name="thirdMargin"
                isRequired
                addonRight="%"
              />
              <DatePicker<FormValues> name="thirdMarginDate" isRequired />
            </MarginContainer>
          </MarginWrapper>

          <ButtonGroup gap="1">
            <ChakraButton
              colorScheme="red"
              isLoading={isSubmitting}
              onClick={methods.handleSubmit(submitHandler)}
            >
              {useNameTranslate('save')}
            </ChakraButton>
            <Button
              colorScheme="secondary"
              buttonType="reset"
              onClick={toExhibitions}
            >
              {useNameTranslate('cancel')}
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
    </FormProvider>
  );
};
