/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Contracts_Enums_BuildingType } from '../models/ITEPortal_Contracts_Enums_BuildingType';
import type { ITEPortal_Domain_Dto_AddCategoryDto } from '../models/ITEPortal_Domain_Dto_AddCategoryDto';
import type { ITEPortal_Domain_Dto_AdminCategoryResourcesSummary } from '../models/ITEPortal_Domain_Dto_AdminCategoryResourcesSummary';
import type { ITEPortal_Domain_Dto_Category_CategoryOrderIndexDto } from '../models/ITEPortal_Domain_Dto_Category_CategoryOrderIndexDto';
import type { ITEPortal_Domain_Dto_CategoryDropdownDto } from '../models/ITEPortal_Domain_Dto_CategoryDropdownDto';
import type { ITEPortal_Domain_Dto_CategoryDto } from '../models/ITEPortal_Domain_Dto_CategoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CategoryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_CategoryDropdownDto Success
     * @throws ApiError
     */
    public getCategoriesAdminSummaries(): CancelablePromise<Array<ITEPortal_Domain_Dto_CategoryDropdownDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/categories/admin-summaries',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_CategoryDto Success
     * @throws ApiError
     */
    public getCategories({
        pageNumber,
        pageSize,
        buildingType,
        parentCategoryId,
        search,
        product,
    }: {
        pageNumber?: number,
        pageSize?: number,
        buildingType?: ITEPortal_Contracts_Enums_BuildingType,
        parentCategoryId?: number,
        search?: string,
        product?: string,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_CategoryDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/categories',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'buildingType': buildingType,
                'parentCategoryId': parentCategoryId,
                'search': search,
                'product': product,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postCategories({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_AddCategoryDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/categories',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putCategories({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_AddCategoryDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/categories',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_CategoryDto Success
     * @throws ApiError
     */
    public getCategoriesPriceList({
        exhibitionId,
        sellingOfficeId,
        buildingType,
    }: {
        exhibitionId?: number,
        sellingOfficeId?: number,
        buildingType?: ITEPortal_Contracts_Enums_BuildingType,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_CategoryDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/categories/price-list',
            query: {
                'ExhibitionId': exhibitionId,
                'SellingOfficeId': sellingOfficeId,
                'BuildingType': buildingType,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_AdminCategoryResourcesSummary Success
     * @throws ApiError
     */
    public getCategoriesResources({
        name,
    }: {
        name: string,
    }): CancelablePromise<Record<string, ITEPortal_Domain_Dto_AdminCategoryResourcesSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/categories/{name}/resources',
            path: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_CategoryDto Success
     * @throws ApiError
     */
    public getCategoriesParentCategories(): CancelablePromise<Array<ITEPortal_Domain_Dto_CategoryDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/categories/parent-categories',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postCategoriesImage({
        name,
        formData,
    }: {
        name: string,
        formData?: {
            image?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/categories/{name}/image',
            path: {
                'name': name,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postCategoriesResourcesBatch({
        formData,
    }: {
        formData?: {
            importCategoryResourcesFile?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/categories/resources/batch',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postCategoriesOrderIndex({
        requestBody,
    }: {
        requestBody?: Array<ITEPortal_Domain_Dto_Category_CategoryOrderIndexDto>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/categories/order-index',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
