import * as v from 'zod';
import { ITEPortal_Domain_Dto_ExhibitionDto } from '@/api';
import { Expect, MatchTypesKeys } from '@/types/support-types';

export const IExhibition = v.object({
  id: v.number(),
  description: v.string(),
  endDate: v.string(),
  firstMargin: v.number(),
  firstMarginDate: v.string(),
  logoUrl: v.string().nullish(),
  name: v.string(),
  place: v.string(),
  secondMargin: v.number(),
  secondMarginDate: v.string(),
  startDate: v.string(),
  technicalManagers: v.array(v.string()),
  thirdMargin: v.number(),
  orderCreationLastDate: v.string().nullish(),
  thirdMarginDate: v.string(),
});

export const isValidExhibition = (json: ITEPortal_Domain_Dto_ExhibitionDto) => {
  return IExhibition.safeParse(json).success;
};

type IExhibitionSchemaType = v.infer<typeof IExhibition>;

type Matched = MatchTypesKeys<
  ITEPortal_Domain_Dto_ExhibitionDto,
  IExhibitionSchemaType
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type test = Expect<Matched>;
