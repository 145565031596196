import React, { useEffect, useState } from 'react';
import {
  useForm,
  FormProvider,
  useController,
  FieldValues,
} from 'react-hook-form';
import {
  ButtonGroup,
  FormControl,
  Stack,
  Button as ChakraButton,
} from '@chakra-ui/react';
import Input from '@/components/Fields/Input';
import { Button } from '@/components/Buttons/Button/Button';
import styled from 'styled-components';
import { useNameTranslate } from '@/hooks/useStringName';
import { ROLES } from '@/constants/admin-pages';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { ADD_USER, EDIT_USER, EDIT_USER_INFO } from '@/constants/endpoints';
import fetchApi from '@/utils/fetchAPI';
import { FetchMethods } from '@/utils/constants';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useFormErrorsHandler } from '../../../../hooks/useFormErrorsHandler';

interface NewUsersFormProps {
  type: string;
}

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

type OptionType = { label?: string; value: string | number };

export const NewUsersForm = ({ type }: NewUsersFormProps): JSX.Element => {
  const { userId } = useParams({ strict: false });
  const editForm = type === 'edit';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editData, setEditData] = useState<any>();
  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onChange',
  });

  const { processErrorsObject } = useFormErrorsHandler(methods);

  useEffect(
    function setFormValuesOfLoadedModel() {
      methods.reset({
        ...editData,
      });
    },
    [editData]
  );

  const {
    field: { ...roleProps },
  } = useController({ name: 'role', control: methods.control });

  const toUsers = () => {
    navigate({
      to: '/admin/users',
      replace: true,
    });
  };

  useEffect(function loadUserDataIfFormOpenedForEdit() {
    if (type === 'edit') {
      const fetchData = async () => {
        setIsSubmitting(true);
        try {
          const { data } = await fetchApi(EDIT_USER_INFO(userId));

          data.data.role = ROLES.find(
            (roleOption: OptionType) => roleOption?.value === data.data.role
          );

          Object.keys(data).forEach((field) => {
            methods.setValue(field, data[field]);
          });

          setEditData(data.data);
        } catch (error: any) {
          //
        } finally {
          setIsSubmitting(false);
        }
      };

      fetchData();
    }
  }, []);

  const submitHandler = async (values: FieldValues) => {
    const method = editForm ? FetchMethods.Put : FetchMethods.Post;
    const link = editForm ? EDIT_USER : ADD_USER;

    const data = { ...values, role: values?.role?.value };

    setIsSubmitting(true);
    try {
      await fetchApi(link, {
        method: method,
        data,
      });

      await toUsers();
    } catch (error: any) {
      processErrorsObject(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitHandler)}>
        <Stack spacing="5">
          <FormControlWrapper>
            <Input
              label={useNameTranslate('full-name')}
              maxWidth="100%"
              name="name"
              defaultValue={editData?.['name']}
              isRequired
            />
            <SelectComponent
              label={useNameTranslate('role')}
              options={ROLES}
              defaultValue={editData?.['role']}
              isRequired
              {...roleProps}
            />
            <Input
              label={useNameTranslate('emailPlaceholder')}
              maxWidth="100%"
              name="email"
              defaultValue={editData?.['email']}
              isRequired
            />
            <Input
              label={useNameTranslate('phone')}
              maxWidth="100%"
              name="phoneNumber"
              defaultValue={editData?.['phoneNumber']}
              isRequired
            />
          </FormControlWrapper>
          <ButtonGroup gap="1">
            <ChakraButton
              colorScheme="red"
              isLoading={isSubmitting}
              onClick={methods.handleSubmit(submitHandler)}
            >
              {useNameTranslate('save')}
            </ChakraButton>
            <Button
              colorScheme="secondary"
              buttonType="reset"
              onClick={toUsers}
            >
              {useNameTranslate('cancel')}
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
    </FormProvider>
  );
};
