import { useTable, useSortBy, Column } from 'react-table';
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import i18n from '@/i18n';

interface TableProps<T extends object = object> {
  columns: Array<Column<T>>;
  data: Array<T>;
}

export const Table = <T extends object = object>({
  columns,
  data,
}: TableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: columns.map((column: any) => {
            if (column.show === false) {
              return column.accessor || column.id;
            }
          }),
        },
      },
      useSortBy
    );

  const isEmpty = rows.length === 0;

  return (
    <>
      <ChakraTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any, headerIndex) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <Th
                  userSelect="none"
                  {...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    width: column?.width,
                  })}
                  key={headerIndex}
                >
                  <Flex alignItems="center">
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon ml={1} w={4} h={4} />
                      ) : (
                        <ChevronUpIcon ml={1} w={4} h={4} />
                      )
                    ) : (
                      ''
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);

            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={cellIndex}
                      verticalAlign="top"
                    >
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraTable>
      {isEmpty && (
        <Box m={4} mt={6} display="flex" justifyContent="center" w="100%">
          <Text color="gray.400">{i18n.t('no-data')}</Text>
        </Box>
      )}
    </>
  );
};
