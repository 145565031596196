/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_ExhibitorStandDto } from '../models/ITEPortal_Domain_Dto_ExhibitorStandDto';
import type { ITEPortal_Domain_Dto_Stand_GetStandFileDto } from '../models/ITEPortal_Domain_Dto_Stand_GetStandFileDto';
import type { ITEPortal_Domain_Dto_Stand_GetStandProjectDto } from '../models/ITEPortal_Domain_Dto_Stand_GetStandProjectDto';
import type { ITEPortal_Domain_Dto_Stand_StandProjectChangesDto } from '../models/ITEPortal_Domain_Dto_Stand_StandProjectChangesDto';
import type { ITEPortal_Domain_Dto_Stand_StandProjectDto } from '../models/ITEPortal_Domain_Dto_Stand_StandProjectDto';
import type { ITEPortal_Domain_Dto_Stand_StandStatusUpdateDto } from '../models/ITEPortal_Domain_Dto_Stand_StandStatusUpdateDto';
import type { ITEPortal_Domain_Dto_StandDto } from '../models/ITEPortal_Domain_Dto_StandDto';
import type { ITEPortal_Domain_Dto_StandSummariesDto } from '../models/ITEPortal_Domain_Dto_StandSummariesDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StandService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_StandSummariesDto Success
     * @throws ApiError
     */
    public getExhibitionsExhibitorStands({
        exhibitionId,
    }: {
        exhibitionId: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_StandSummariesDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitions/{exhibitionId}/exhibitor-stands',
            path: {
                'exhibitionId': exhibitionId,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_StandSummariesDto Success
     * @throws ApiError
     */
    public getExhibitorStands({
        standId,
    }: {
        standId: number,
    }): CancelablePromise<ITEPortal_Domain_Dto_StandSummariesDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitor-stands/{standId}',
            path: {
                'standId': standId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_StandDto Success
     * @throws ApiError
     */
    public getExhibitionsStands({
        id,
        exhibitionId,
    }: {
        id: number,
        exhibitionId: string,
    }): CancelablePromise<ITEPortal_Domain_Dto_StandDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitions/{exhibitionId}/stands/{id}',
            path: {
                'id': id,
                'exhibitionId': exhibitionId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putExhibitionsStands({
        id,
        exhibitionId,
        requestBody,
    }: {
        id: number,
        exhibitionId: string,
        requestBody?: ITEPortal_Domain_Dto_StandDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exhibitions/{exhibitionId}/stands/{id}',
            path: {
                'id': id,
                'exhibitionId': exhibitionId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_ExhibitorStandDto Success
     * @throws ApiError
     */
    public getExhibitionsExhibitorsStands({
        exhibitionId,
        exhibitorId,
    }: {
        exhibitionId: number,
        exhibitorId: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_ExhibitorStandDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitions/{exhibitionId}/exhibitors/{exhibitorId}/stands',
            path: {
                'exhibitionId': exhibitionId,
                'exhibitorId': exhibitorId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postExhibitionsStands({
        exhibitionId,
        requestBody,
    }: {
        exhibitionId: number,
        requestBody?: ITEPortal_Domain_Dto_StandDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitions/{exhibitionId}/stands',
            path: {
                'exhibitionId': exhibitionId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_Stand_GetStandProjectDto Success
     * @throws ApiError
     */
    public getStandsProject({
        standId,
    }: {
        standId: number,
    }): CancelablePromise<ITEPortal_Domain_Dto_Stand_GetStandProjectDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stands/{standId}/project',
            path: {
                'standId': standId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public postStandsProject({
        standId,
        requestBody,
    }: {
        standId: number,
        requestBody?: ITEPortal_Domain_Dto_Stand_StandProjectDto,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stands/{standId}/project',
            path: {
                'standId': standId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public postStandsUpdateProjectStatus({
        standId,
        projectId,
        requestBody,
    }: {
        standId: number,
        projectId: number,
        requestBody?: ITEPortal_Domain_Dto_Stand_StandStatusUpdateDto,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stands/{standId}/update-project-status/{projectId}',
            path: {
                'standId': standId,
                'projectId': projectId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public putStandsProject({
        standId,
        projectId,
        requestBody,
    }: {
        standId: number,
        projectId: number,
        requestBody?: ITEPortal_Domain_Dto_Stand_StandProjectDto,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/stands/{standId}/project/{projectId}',
            path: {
                'standId': standId,
                'projectId': projectId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putStandsProjectImage({
        standId,
        projectId,
        formData,
    }: {
        standId: number,
        projectId: number,
        formData?: {
            image?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/stands/{standId}/project/{projectId}/image',
            path: {
                'standId': standId,
                'projectId': projectId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public postStandsProjectChanges({
        standId,
        projectId,
        requestBody,
    }: {
        standId: number,
        projectId: number,
        requestBody?: ITEPortal_Domain_Dto_Stand_StandProjectChangesDto,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stands/{standId}/project/{projectId}/changes',
            path: {
                'standId': standId,
                'projectId': projectId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putStandsProjectChanges({
        standId,
        projectId,
        changesId,
        formData,
    }: {
        standId: number,
        projectId: number,
        changesId: number,
        formData?: {
            file?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/stands/{standId}/project/{projectId}/changes/{changesId}',
            path: {
                'standId': standId,
                'projectId': projectId,
                'changesId': changesId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_Stand_GetStandFileDto Success
     * @throws ApiError
     */
    public getStandsFiles({
        standId,
    }: {
        standId: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_Stand_GetStandFileDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stands/{standId}/files',
            path: {
                'standId': standId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putStandsFiles({
        standId,
        formData,
    }: {
        standId: number,
        formData?: {
            file?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/stands/{standId}/files',
            path: {
                'standId': standId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteStandsFiles({
        standId,
        fileId,
    }: {
        standId: number,
        fileId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/stands/{standId}/files/{fileId}',
            path: {
                'standId': standId,
                'fileId': fileId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getStandsZip({
        standId,
    }: {
        standId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stands/{standId}/zip',
            path: {
                'standId': standId,
            },
        });
    }

}
