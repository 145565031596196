/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Contracts_Enums_BuildingType } from '../models/ITEPortal_Contracts_Enums_BuildingType';
import type { ITEPortal_Domain_Dto_AddPriceListItemDto } from '../models/ITEPortal_Domain_Dto_AddPriceListItemDto';
import type { ITEPortal_Domain_Dto_AdminPriceListItem } from '../models/ITEPortal_Domain_Dto_AdminPriceListItem';
import type { ITEPortal_Domain_Dto_PriceListItemSummary } from '../models/ITEPortal_Domain_Dto_PriceListItemSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PriceListItemService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_PriceListItemSummary Success
     * @throws ApiError
     */
    public getPricelistItems({
        exhibitionId,
        currency,
        sellingOfficeId,
        categoryIds,
        productId,
        buildingType,
    }: {
        exhibitionId?: number,
        currency?: string,
        sellingOfficeId?: number,
        categoryIds?: string,
        productId?: number,
        buildingType?: ITEPortal_Contracts_Enums_BuildingType,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_PriceListItemSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pricelist-items',
            query: {
                'ExhibitionId': exhibitionId,
                'Currency': currency,
                'SellingOfficeId': sellingOfficeId,
                'CategoryIds': categoryIds,
                'ProductId': productId,
                'BuildingType': buildingType,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_AdminPriceListItem Success
     * @throws ApiError
     */
    public getExhibitionsPriceListsItems({
        exhibitionId,
        priceListId,
    }: {
        exhibitionId: number,
        priceListId: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_AdminPriceListItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitions/{exhibitionId}/price-lists/{priceListId}/items',
            path: {
                'exhibitionId': exhibitionId,
                'priceListId': priceListId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns number Created
     * @throws ApiError
     */
    public postExhibitionsPriceListsItems({
        exhibitionId,
        priceListId,
        requestBody,
    }: {
        exhibitionId: number,
        priceListId: number,
        requestBody?: ITEPortal_Domain_Dto_AddPriceListItemDto,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitions/{exhibitionId}/price-lists/{priceListId}/items',
            path: {
                'exhibitionId': exhibitionId,
                'priceListId': priceListId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postExhibitionsPriceListsItemsBatch({
        exhibitionId,
        priceListId,
        formData,
    }: {
        exhibitionId: number,
        priceListId: number,
        formData?: {
            importPriceListItems?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitions/{exhibitionId}/price-lists/{priceListId}/items/batch',
            path: {
                'exhibitionId': exhibitionId,
                'priceListId': priceListId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postPricelistItemsBatch({
        formData,
    }: {
        formData?: {
            importPriceListsFile?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pricelist-items/batch',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public putExhibitionsPriceListsItems({
        exhibitionId,
        priceListId,
        priceListItemId,
        requestBody,
    }: {
        exhibitionId: number,
        priceListId: number,
        priceListItemId: number,
        requestBody?: ITEPortal_Domain_Dto_AddPriceListItemDto,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exhibitions/{exhibitionId}/price-lists/{priceListId}/items/{priceListItemId}',
            path: {
                'exhibitionId': exhibitionId,
                'priceListId': priceListId,
                'priceListItemId': priceListItemId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public deleteExhibitionsPriceListsItems({
        exhibitionId,
        priceListId,
        priceListItemId,
    }: {
        exhibitionId: number,
        priceListId: number,
        priceListItemId: number,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exhibitions/{exhibitionId}/price-lists/{priceListId}/items/{priceListItemId}',
            path: {
                'exhibitionId': exhibitionId,
                'priceListId': priceListId,
                'priceListItemId': priceListItemId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
