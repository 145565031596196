import * as vali from 'zod';
import i18n from '@/i18n';

const OptionSchema = vali.object(
  {
    value: vali.union([vali.coerce.string(), vali.number()]),
    label: vali.coerce.string().nullable(),
  },
  {
    required_error: i18n.t('required-field'),
  }
);

const SchemaWithImages = vali.object({
  imageUrls: vali.array(vali.string()).optional().nullable(),
  imageFiles: vali.array(vali.instanceof(File)).optional().nullable(),
});

export const SharedProductValidationSchema = vali.object({
  name: vali
    .string({
      invalid_type_error: i18n.t('required-field'),
    })
    .min(1, {
      message: i18n.t('required-field'),
    }),
  displayNameRu: vali
    .string({
      invalid_type_error: i18n.t('required-field'),
    })
    .min(1, {
      message: i18n.t('required-field'),
    }),
  displayNameEn: vali
    .string({
      invalid_type_error: i18n.t('required-field'),
    })
    .min(1, {
      message: i18n.t('required-field'),
    }),
  buildingTypes: vali.array(OptionSchema, {
    required_error: i18n.t('required-field'),
  }),
  descriptionRu: vali.coerce.string().optional().nullable(),
  descriptionEn: vali.coerce.string().optional().nullable(),
  categoryName: OptionSchema,
});

export const CommonProductValidationSchema = vali
  .object({
    linkedProductIds: vali.array(OptionSchema).optional(),
  })
  .merge(SharedProductValidationSchema)
  .merge(SchemaWithImages);

export const BuildingProductValidationSchema = vali
  .object({
    imageDescriptionRu: vali
      .string({
        invalid_type_error: i18n.t('required-field'),
      })
      .optional()
      .nullable(),
    imageDescriptionEn: vali
      .string({
        invalid_type_error: i18n.t('required-field'),
      })
      .optional()
      .nullable(),
    additionalNotesRu: vali
      .string({
        invalid_type_error: i18n.t('required-field'),
      })
      .optional()
      .nullable(),
    additionalNotesEn: vali
      .string({
        invalid_type_error: i18n.t('required-field'),
      })
      .optional()
      .nullable(),
  })
  .merge(SharedProductValidationSchema)
  .merge(SchemaWithImages);

export const TemporaryStaffValidationSchema = vali
  .object({
    fieldType: OptionSchema,
  })
  .merge(SharedProductValidationSchema);

export type CommonProductValidationSchemaType = vali.infer<
  typeof CommonProductValidationSchema
>;
export type BuildingProductValidationSchemaType = vali.infer<
  typeof BuildingProductValidationSchema
>;
export type TemporaryStaffValidationSchemaType = vali.infer<
  typeof TemporaryStaffValidationSchema
>;
