import React, { useState } from 'react';
import { Table } from '@/components/Table/Table';
import { useTranslation } from 'react-i18next';
import { Card } from '@/components/Card/Card';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  ButtonGroup,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { useNavigate, useParams, Link } from '@tanstack/react-router';
import { Column, Row } from 'react-table';
import fetchApi from '@/utils/fetchAPI';
import {
  DOWNLOAD_EXHIBITOR_CSV,
  EXHIBITORS_FROM_EXHIBITOR,
} from '@/constants/endpoints';
import { Loader } from '@/components/Loader/Loader';
import { FetchMethods } from '@/utils/constants';
import {
  Button as OrderButton,
  Button,
} from '@/components/Buttons/Button/Button';
import { CiImport } from 'react-icons/ci';
import { ToExhibition } from '@/layout/AdminAdditionalPanel/ToExhibition';
import { useNameTranslate } from '@/hooks/useStringName';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { IdType } from '@/model/common';
import { toast } from 'react-toastify';
import { ModalFactory } from '../../../components/Modal/api';
import { typedAxios } from '../../../api/typed-axios';
import { useTableLoader } from '../../../components/Table/useTableLoader';
import {
  ITEPortal_Domain_Dto_AdminExhibitorSummary,
  ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto,
} from '@/api';
import { OrdersIcon } from '@/components/Icons/Orders';
import { DateUtils } from '@/utils/date-utils';

const OptionWrapper = styled(Flex)`
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
`;

const LoaderWrapper = styled(Flex)`
  justify-content: center;
  padding-top: 10%;
`;

const ToolBarWrapper = styled(Box)`
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;

const ButtonContentContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const TransparentButtonsTextContainer = styled(Text)`
  font-size: 16px;
  color: var(--chakra-colors-gray-600);
`;

type Extended_ITEPortal_Domain_Dto_AdminExhibitorSummary =
  ITEPortal_Domain_Dto_AdminExhibitorSummary & {
    lastVisitDate: ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto['lastVisitDate'];
  };

interface ExhibitionOptionsType {
  row: Row<Extended_ITEPortal_Domain_Dto_AdminExhibitorSummary>;
}

export const PersonalAccounts = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { exhibitionId } = useParams({ strict: false });
  const {
    data: personalAccountsData,
    isLoading,
    refresh,
  } = useTableLoader<Extended_ITEPortal_Domain_Dto_AdminExhibitorSummary>({
    ignorePagination: true,
    resource: `${EXHIBITORS_FROM_EXHIBITOR}?exhibitionId=${exhibitionId}`,
    map: (data) => {
      return data.map((ac) => {
        if (ac.exhibitorEmployees && ac.exhibitorEmployees.length > 0) {
          ac.lastVisitDate = DateUtils.findLatestDate(
            ac.exhibitorEmployees.map((empl) => empl.lastVisitDate)
          );
        }

        return ac;
      });
    },
  });
  const [openConfirm, setOpenConfirm] = useState(false);

  const methods = useForm({
    mode: 'onBlur',
  });

  const PersonalAccountsOptions = ({ row }: ExhibitionOptionsType) => {
    const rowValues = row.values;

    return (
      <OptionWrapper>
        <Link
          title="Placed orders"
          to="/admin/exhibitions/$exhibitionId/view/placed-orders"
          params={{ exhibitionId }}
          search={{
            exhibitorId: rowValues.id,
          }}
        >
          <OrdersIcon />
        </Link>
        <Link
          to="/admin/exhibitions/$exhibitionId/view/personal-accounts/$accountId/edit"
          params={{ accountId: rowValues.id, exhibitionId }}
        >
          <EditIcon />
        </Link>
        <Link onClick={() => showDeleteModal(rowValues?.id)}>
          <DeleteIcon />
        </Link>
      </OptionWrapper>
    );
  };

  const showDeleteModal = (id: IdType) => {
    ModalFactory.confirm({
      Component: () => <>{t('delete-exhibitor-message')}</>,
      onOk: async () => {
        try {
          await typedAxios.exhibitor.deleteExhibitionsExhibitors({
            exhibitionId: +exhibitionId,
            exhibitorId: +id,
          });

          toast.success(t('exhibitor-deleted'));

          ModalFactory.close();
          refresh();
        } catch {
          toast.error(t('unable-to-delete', { entity: t('exhibitor_') }));
        }
      },
    });
  };

  const PersonalAccountsToolbar = () => {
    return (
      <ToolBarWrapper>
        <Button colorScheme="transparent" onClick={importCSV}>
          <ButtonContentContainer>
            <CiImport size="20px" />
            <TransparentButtonsTextContainer>
              {t('importCSV')}
            </TransparentButtonsTextContainer>
          </ButtonContentContainer>
        </Button>
        <Button colorScheme="red" onClick={addPersonalAccount}>
          {t('addPerson')}
        </Button>
      </ToolBarWrapper>
    );
  };

  const importCSV = () => {
    setOpenConfirm(!openConfirm);
  };

  const closeModal = () => setOpenConfirm(false);

  const submitHandler = async (values: FieldValues) => {
    try {
      const { data } = await fetchApi(
        DOWNLOAD_EXHIBITOR_CSV(exhibitionId),
        {
          method: FetchMethods.Post,
          responseType: 'blob',
          data: { importFile: values?.['upload-csv'][0] },
        },
        { 'Content-Type': 'multipart/form-data' }
      );

      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'ErrorRows.csv');
        document.body.appendChild(link);
        link.click();
      }
    } finally {
      refresh();
    }

    closeModal();
  };

  const ImportCSVModal = () => {
    return (
      <Modal isOpen={openConfirm} onClose={closeModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('set-file')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form>
                <FormControl>
                  <UploadFileDropzone
                    label={useNameTranslate('upload-csv')}
                    name="upload-csv"
                    accept={{ 'text/csv': ['.csv'] }}
                  />
                </FormControl>
              </form>
            </FormProvider>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <ButtonGroup gap="1" justifyContent="center">
              <OrderButton
                colorScheme="red"
                onClick={methods.handleSubmit(submitHandler)}
              >
                {t('choose')}
              </OrderButton>
              <OrderButton colorScheme="secondary" onClick={closeModal}>
                {t('cancel')}
              </OrderButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const personalAccountsColumns = React.useMemo<
    Array<Column<Extended_ITEPortal_Domain_Dto_AdminExhibitorSummary>>
  >(
    () => [
      {
        accessor: 'id',
        show: false,
      },
      {
        Header: `${t('participant-name')}`,
        accessor: 'name',
      },
      {
        Header: `${t('sales-office')}`,
        accessor: 'sellingOffice',
      },
      {
        Header: `${t('last-visit-date')}`,
        accessor: 'lastVisitDate',
      },
      {
        Header: `${t('technical-manager')}`,
        accessor: 'technicalManagerEmail',
      },
      {
        Header: t('agent'),
        accessor: 'agent',
        Cell: ({ value }) => {
          if (value && value.name) {
            return <>{value.name}</>;
          }

          return <>-</>;
        },
      },
      {
        Header: '',
        id: 'options',
        width: '10%',
        Cell: ({ row }) => <PersonalAccountsOptions row={row} />,
      },
    ],
    []
  );

  const addPersonalAccount = () => {
    navigate({
      to: '/admin/exhibitions/$exhibitionId/view/personal-accounts/add',
      params: {
        exhibitionId: exhibitionId,
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <ToExhibition />
          <PersonalAccountsToolbar />

          <Card>
            <Table
              columns={personalAccountsColumns}
              data={personalAccountsData}
            />
            <ImportCSVModal />
          </Card>
        </>
      )}
    </>
  );
};
