import { IdType, MarginType } from '@/model/common';
import { Nullable } from '@/types/support-types';
import { createApi, createStore } from 'effector';
import { produce } from 'immer';
import { useUnit } from 'effector-react';
import { leaveExhibitionOrderEvent } from '@/store/effector/cleanup';
import { typedObjectKeys } from '@/utils/typed-object-keys';
import { IExhibition } from '@/model/IExhibition';

type InitialState = {
  margin: MarginType;
  currency: Nullable<string>;
  exhibitionName: Nullable<string>;
  sellingOfficeId: IdType;
  exhibitionId: IdType;
  exhibition: IExhibition | null;
};

export const exhibitionInitialState: InitialState = {
  exhibitionName: '',
  margin: {
    firstMargin: null,
    firstMarginDate: null,
    secondMargin: undefined,
    secondMarginDate: undefined,
    thirdMargin: undefined,
    thirdMarginDate: undefined,
  },
  currency: null,
  sellingOfficeId: 0,
  exhibitionId: 0,
  exhibition: null,
};

export const $exhibition = createStore<InitialState>(
  exhibitionInitialState
).reset(leaveExhibitionOrderEvent);

export const exhibitionApi = createApi($exhibition, {
  prepare: (state, payload: InitialState) => {
    return produce(state, (draft) => {
      draft.exhibitionName = payload.exhibitionName;
      draft.margin = payload.margin;
      draft.sellingOfficeId = payload.sellingOfficeId;
      draft.currency = payload.currency;
      draft.exhibitionId = payload.exhibitionId;
    });
  },

  update: (state, payload: Partial<InitialState>) => {
    return produce(state, (draft) => {
      typedObjectKeys(payload).forEach((key) => {
        if (key && payload[key] && payload[key] !== undefined) {
          // @ts-expect-error fix types
          draft[key] = payload[key];
        }
      });
    });
  },

  setName: (state, payload: string) => {
    return produce(state, (draft) => {
      draft.exhibitionName = payload;
    });
  },

  setInstance: (state, payload: IExhibition | null) => {
    return produce(state, (draft) => {
      draft.exhibition = payload;
    });
  },
});

export const useExhibition = () => useUnit($exhibition);
