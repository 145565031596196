import { useEffect, useState } from 'react';
import { typedAxios } from '@/api/typed-axios';
import getConfig from '../defaultConfig';
import { createExhibitionOrderServiceRoute } from '@/features/Routing/protected/exhibitions-by-role';
import { IdType, DomainRecord } from '@/model/common';
import { ProductUtils } from '@/utils/product-utils';
import { useExhibition } from '@/store/effector/exhibition';
import { useStand } from '@/store/effector/stand';
import { useCatalog } from '@/store/effector/catalog';

export const useProductsLoader = (opts: { categoryId: IdType }) => {
  const { categoryId } = opts;

  const { flatMap } = useCatalog();
  const [isLoading, setLoading] = useState(false);
  const [productItems, setProductItems] = useState<DomainRecord[]>([]);
  const { standForOrder } = useStand();
  const { sellingOfficeId } = useExhibition();
  const { exhibitionId } = createExhibitionOrderServiceRoute.useParams();
  const categoriesSource = [...flatMap.values()];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await typedAxios.priceListItem.getPricelistItems({
          exhibitionId: +exhibitionId,
          sellingOfficeId: +sellingOfficeId,
          buildingType: standForOrder?.buildingType,
          categoryIds: categoryId + '' || undefined,
        });

        const productItemCardObjects = data?.map((product) => {
          const category = categoriesSource.find((cat) => {
            return cat.id === product.categoryId;
          });

          return ProductUtils.createProductFromPriceListItem(product, {
            category,
          });
        });

        const productItemsObjects = getConfig(productItemCardObjects);

        setProductItems(productItemsObjects);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryId]);

  return {
    isLoading,
    productItems,
  };
};
