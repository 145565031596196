import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import styled from 'styled-components';

import { Settings } from '@/pages/AdminPanel/Settings/Settings';
import { ChangePassword } from '@/pages/AdminPanel/Settings/ChangePassword';
import { LogoTab } from './LogoTab';
import { ManualUploaderTab } from './ManualUploaderTab';
import {
  SettingsPageSearchSchemaType,
  settingsRoute,
} from '../../../features/Routing/protected/settings';
import { useNavigate } from '@tanstack/react-router';
import { DiscountsTab } from './Disounts';

const tabStyles = {
  borderColor: 'blackAlpha.200',
  borderBottomColor: '#F7FAFC',
  color: 'red',
};

const TabWrapper = styled(TabPanel)`
  padding-top: 50px;
`;

const TabsMap: Record<SettingsPageSearchSchemaType['tab'], number> = {
  global: 0,
  smtp: 1,
  logo: 2,
  manual: 3,
  discount: 4,
};

export const SettingsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { tab = 'global' } = settingsRoute.useSearch();
  const navigate = useNavigate();

  const selectedTabFromUrl = TabsMap[tab] || 0;

  return (
    <>
      <Tabs
        index={selectedTabFromUrl}
        variant="enclosed"
        onChange={(index) => {
          navigate({
            to: './',
            search: {
              tab: Object.keys(TabsMap)[index],
            },
          });
        }}
      >
        <TabList>
          <Tab _selected={tabStyles}>{t('main-settings')}</Tab>
          <Tab _selected={tabStyles}>{t('change-password')}</Tab>
          <Tab _selected={tabStyles}>{t('logo')}</Tab>
          <Tab _selected={tabStyles}>{t('participantManual')}</Tab>
          <Tab _selected={tabStyles}>{t('cleaning-discounts')}</Tab>
        </TabList>
        <TabPanels>
          <TabWrapper>
            <Settings />
          </TabWrapper>
          <TabWrapper>
            <ChangePassword />
          </TabWrapper>
          <TabWrapper>
            <LogoTab />
          </TabWrapper>
          <TabWrapper>
            <ManualUploaderTab />
          </TabWrapper>
          <TabWrapper>
            <DiscountsTab />
          </TabWrapper>
        </TabPanels>
      </Tabs>
    </>
  );
};
